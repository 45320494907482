define('resource-verified-ember/models/achievement', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    achievement: _emberData.default.attr('string'),
    verification_status: _emberData.default.attr('string'),
    verified: _emberData.default.attr('boolean'),
    verifier: _emberData.default.attr({}),
    verification_request: _emberData.default.attr({}),
    document_count: _emberData.default.attr('number'),
    workExperience: _emberData.default.belongsTo('workExperience'),
    education: _emberData.default.belongsTo('education')
  });
});