define('resource-verified-ember/mixins/modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNames: ['modal'],

    close: function close() {
      this.set('isOpen', false);
      $('body').removeClass('modal-open');
      this.$('.modal-backdrop').remove();
      $(document).unbind('keyup.modalEsc');
    },
    didRender: function didRender() {
      var _this = this;

      $('body').addClass('modal-open');

      if (!this.$('.modal-backdrop').length) {
        this.$().prepend('<div class="modal-backdrop"></div>');
      }

      this.$().find('.modal-backdrop').click(function () {
        _this.close();
      });

      // close on esc key press
      $(document).on('keyup.modalEsc', function (e) {
        if (e.keyCode == 27) {
          _this.close();
        }
      });
    }
  });
});