define('resource-verified-ember/components/user/save-to-verify-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['save-to-verify-button'],
    isConfirmationVisible: false,
    closeConfirmation: function closeConfirmation() {
      Ember.$(document).off('click.hideConfirmation');
      this.set('isConfirmationVisible', false);
    },


    actions: {
      showConfirmation: function showConfirmation() {
        var _this = this;

        this.set('isConfirmationVisible', true);

        Ember.run.later(function () {
          Ember.$(document).on('click.hideConfirmation', function (e) {
            if (!$(e.target).hasClass('save')) {
              _this.closeConfirmation();
            }
          });
        });
      },
      hideConfirmation: function hideConfirmation() {
        this.closeConfirmation();
      },
      confirm: function confirm() {
        this.action();
        this.closeConfirmation();
      }
    }
  });
});