define('resource-verified-ember/models/coupon', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    expires_at: _emberData.default.attr('date'),
    max_users: _emberData.default.attr('number'),
    max_uses: _emberData.default.attr('number'),
    users: _emberData.default.attr('number'),
    applies_to: _emberData.default.attr([]),
    discount_type: _emberData.default.attr('string'),
    discount_amount: _emberData.default.attr('number'),
    stripe_code: _emberData.default.attr('string'),

    discount_string: Ember.computed('discount_type', 'discount_amount', function () {
      if (this.get('discount_type') == 'percent') {
        return this.get('discount_amount') + '%';
      } else {
        return '$' + this.get('discount_amount').toFixed(2);
      }
    })
  });
});