define('resource-verified-ember/components/user/criminal-check', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, {
    classNames: ['criminal-check'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    search: Ember.inject.service(),
    user: Ember.inject.service(),

    cloudinarySignatureEndpoint: _emberGetConfig.default.apiFullPath + '/uploads/sign',
    completedBackgroundChecks: [],

    getBackgroundCheck: function getBackgroundCheck() {
      var _this = this;

      if (this.get('userRVID')) {
        var share = '';
        if (this.get('share')) share = this.get('share');
        // if(this.get('user.user.role') === 'admin' || this.get('showCriminalCheck')) {
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('userRVID') + '/background_checks' + share, 'GET').then(function (response) {
          _this.set('backgroundChecks', response.background_checks);
          _this.set('basicChecks', _this.get('backgroundChecks').filterBy('report_type', 'basic').filterBy('url'));
          _this.set('pendingBasicChecks', _this.get('backgroundChecks').filterBy('report_type', 'basic').filterBy('status', 'pending'));
          _this.set('executiveChecks', _this.get('backgroundChecks').filterBy('report_type', 'executive').filterBy('url'));
          _this.set('pendingExecutiveChecks', _this.get('backgroundChecks').filterBy('report_type', 'executive').filterBy('status', 'pending'));

          _this.set('USCriminalChecks', _this.get('backgroundChecks').filterBy('report_type', 'us_criminal_check').filterBy('url'));
          _this.set('pendingUSCriminalChecks', _this.get('backgroundChecks').filterBy('report_type', 'us_criminal_check').filterBy('status', 'pending'));
          _this.set('internationalCriminalChecks', _this.get('backgroundChecks').filterBy('report_type', 'international_criminal_check').filterBy('url'));
          _this.set('pendingInternationalCriminalChecks', _this.get('backgroundChecks').filterBy('report_type', 'international_criminal_check').filterBy('status', 'pending'));
          _this.set('bankruptcyLienChecks', _this.get('backgroundChecks').filterBy('report_type', 'bankruptcies_liens_judgements').filterBy('url'));
          _this.set('pendingBankruptcyLienChecks', _this.get('backgroundChecks').filterBy('report_type', 'bankruptcies_liens_judgements').filterBy('status', 'pending'));

          _this.set('sanctionsWatchlistsChecks', _this.get('backgroundChecks').filterBy('report_type', 'sanctions_watchlists_check').filterBy('url'));
          _this.set('pendingSanctionsWatchlistsChecks', _this.get('backgroundChecks').filterBy('report_type', 'sanctions_watchlists_check').filterBy('status', 'pending'));
          _this.set('requiredInputSanctionsWatchlistsChecks', _this.get('backgroundChecks').filterBy('report_type', 'sanctions_watchlists_check').filterBy('status', 'require_input').filterBy('submitted', false));
          _this.set('completedBackgroundChecks', _this.get('backgroundChecks').filterBy('url'));
          _this.set('lastBackgroundChecks', _this.get('backgroundChecks').filterBy('report_type', 'sanctions_watchlists_check'));

          if (_this.get('basicChecks.length')) _this.set('basicBackgroundCheckURL', _this.get('basicChecks.lastObject').url);
          if (_this.get('executiveChecks.length')) _this.set('executiveBackgroundCheckURL', _this.get('executiveChecks.lastObject').url);
          if (_this.get('USCriminalChecks.length')) _this.set('USCriminalBackgroundCheckURL', _this.get('USCriminalChecks.lastObject').url);
          if (_this.get('internationalCriminalChecks.length')) _this.set('internationalCriminalBackgroundCheckURL', _this.get('internationalCriminalChecks.lastObject').url);
          if (_this.get('bankruptcyLienChecks.length')) _this.set('bankruptcyLienBackgroundCheckURL', _this.get('bankruptcyLienChecks.lastObject').url);
          if (_this.get('sanctionsWatchlistsChecks.length')) _this.set('sanctionsWatchlistsBackgroundCheckURL', _this.get('sanctionsWatchlistsChecks.lastObject').url);
          if (_this.get('lastBackgroundChecks.length')) _this.set('sanctionsWatchlistsBackgroundCheckNeeded', _this.get('lastBackgroundChecks.lastObject').submitted);
          var section = _this.section;
          if (section && _this.get('sanctionsWatchlistsBackgroundCheckNeeded') != undefined && !_this.get('sanctionsWatchlistsBackgroundCheckNeeded')) {
            _this.getBackgroundCheckPopUp(section);
          }
        }).catch(function (errors) {
          _this.handleError(errors);
        });
        // }
      }
    },
    getBackgroundCheckPopUp: function getBackgroundCheckPopUp(section) {
      // var section = this.section;
      if (section == 'bankruptcy_check' && this.isBankruptcyLienPackageShown && this.isUser) {
        this.send('performCheck', 'bankruptcies_liens_judgements');
      } else if (section == 'sanction_watchlist_request' && this.isSanctionsWatchlistsPackageShown && this.isUser && this.hasPendingSanctionsWatchlists) {
        this.send('performCheck', 'sanctions_watchlists_check');
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.getBackgroundCheck();
    },


    userIdObserver: Ember.observer('userRVID', function () {
      this.getBackgroundCheck();
    }),

    isBasicPackageShown: Ember.computed('isUser', 'backgroundChecks', 'basicChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('basicChecks.length')) return false;
      return true;
    }),

    isExecutivePackageShown: Ember.computed('isUser', 'backgroundChecks', 'executiveChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('executiveChecks.length')) return false;
      return true;
    }),
    isUSCriminalPackageShown: Ember.computed('isUser', 'backgroundChecks', 'USCriminalChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('USCriminalChecks.length')) return false;
      return true;
    }),
    isInternationalCriminalPackageShown: Ember.computed('isUser', 'backgroundChecks', 'internationalCriminalChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('internationalCriminalChecks.length')) return false;
      return true;
    }),
    isBankruptcyLienPackageShown: Ember.computed('isUser', 'backgroundChecks', 'bankruptcyLienChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('bankruptcyLienChecks.length')) return false;
      return true;
    }),
    isSanctionsWatchlistsPackageShown: Ember.computed('isUser', 'backgroundChecks', 'sanctionsWatchlistsChecks', function () {
      if (!this.get('isUser') && this.get('backgroundChecks.length') && !this.get('sanctionsWatchlistsChecks.length')) return false;
      return true;
    }),

    hasPendingBasic: Ember.computed.notEmpty('pendingBasicChecks'),
    hasPendingExecutive: Ember.computed.notEmpty('pendingExecutiveChecks'),
    hasPendingUSCriminal: Ember.computed.notEmpty('pendingUSCriminalChecks'),
    hasPendingInternationalCriminal: Ember.computed.notEmpty('pendingInternationalCriminalChecks'),
    hasPendingBankruptcyLien: Ember.computed.notEmpty('pendingBankruptcyLienChecks'),
    hasPendingSanctionsWatchlists: Ember.computed.notEmpty('pendingSanctionsWatchlistsChecks'),
    hasRequiredInputSanctionsWatchlistsChecks: Ember.computed.notEmpty('requiredInputSanctionsWatchlistsChecks'),
    bgCheckName: Ember.computed('bgCheckType', function () {
      switch (this.get('bgCheckType')) {
        case 'us_criminal_check':
          return 'US Criminal Check';
        case 'international_criminal_check':
          return 'International Criminal Check';
        case 'bankruptcies_liens_judgements':
          return 'Bankruptcies, Liens and Judgements Check';
        case 'sanctions_watchlists_check':
          return 'Sanctions, Watchlists, PEP, Adverse Media and SEC Action (US Only) Check';
        default:
          return null;
      }
    }),

    showCheckoutSuccessMessage: function showCheckoutSuccessMessage() {
      var reportType = this.get('bgCheckType');
      var reportName = this.get('bgCheckName');
      this.get('flashMessages').set('flashMessageDefaults.timeout', 10000);
      if (reportType === 'sanctions_watchlists_check') {
        // this.get('flashMessages').success(`Report ordered! Your ${reportName} will be completed and uploaded within 3-5 business days.`);
        this.get('flashMessages').success('Form submitted, Thank you.');
      } else {
        this.get('flashMessages').success('Report ordered! You will receive an email to complete your ' + reportName + ' momentarily.');
      }
      this.get('flashMessages').set('flashMessageDefaults.timeout', 3000);
    },


    actions: {
      criminalCheckUploadComplete: function criminalCheckUploadComplete(id, e, data) {
        var _this2 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('userID') + '/background_checks/' + id, 'PUT', { url: data.result.public_id }).then(function () {
          _this2.get('flashMessages').success('Report added');
          _this2.getBackgroundCheck();
        }).catch(function (errors) {
          _this2.handleError(errors);
        });
      },
      criminalCheckUploadFailure: function criminalCheckUploadFailure(e, data) {
        this.get('flashMessages').danger('File upload failure. Please try again.');
      },
      performCheck: function performCheck(type) {
        this.set('bgCheckType', type);
        this.set('requireDOBAndCountry', false);
        this.set('requireCard', true);
        switch (type) {
          case 'us_criminal_check':
            this.set('paymentAmount', 200);
            break;
          case 'international_criminal_check':
            this.set('paymentAmount', 210);
            break;
          case 'bankruptcies_liens_judgements':
            this.set('paymentAmount', 50);
            break;
          case 'sanctions_watchlists_check':
            this.set('paymentAmount', 50);
            this.set('requireDOBAndCountry', true);
            if (this.get('hasRequiredInputSanctionsWatchlistsChecks')) this.set('requireCard', false);
            break;
        }

        this.set('isSubmitPaymentShown', true);
      },
      submitPayment: function submitPayment(data) {
        var _this3 = this;

        var stripeToken = data.stripeToken;
        var couponCode = data.couponCode;
        var first_name = data.firstName;
        var middle_name = data.middleName;
        var last_name = data.lastName;
        var dob = data.dob;
        var country = data.country;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('userID') + '/background_checks', 'POST', {
          report_type: this.get('bgCheckType'),
          paymentOptions: {
            stripeToken: stripeToken,
            couponCode: couponCode
          },
          first_name: first_name, middle_name: middle_name, last_name: last_name, dob: dob, country: country
        }).then(function (resp) {
          if (_this3.section == 'sanction_watchlist_request') {
            _this3.section = '';
          }
          _this3.getBackgroundCheck();
          _this3.showCheckoutSuccessMessage();
        }).catch(function (errors) {
          _this3.handleError(errors);
        });
      },
      showCriminalCheckByID: function showCriminalCheckByID(url) {
        if (this.get('criminalCheckID') === this.get('userObject.resource_verified_id')) {
          window.open('https://res.cloudinary.com/resourceverified/image/upload/' + url);
        } else {
          this.get('flashMessages').danger('Enter this user\'s ID to view this report');
        }
      }
    }
  });
});