define('resource-verified-ember/components/profile-ad', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['profile-ad'],

    visible: Ember.computed(function () {
      return _emberGetConfig.default.environment === 'production';
    })
  });
});