define('resource-verified-ember/mixins/error-handler', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    flashMessages: Ember.inject.service(),

    handleError: function handleError(error) {
      if (_emberGetConfig.default.environmentName === 'development' || _emberGetConfig.default.environmentName === 'staging') console.log(error);

      this.get('flashMessages').danger(error.errors.message);
    }
  });
});