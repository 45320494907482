define('resource-verified-ember/routes/user/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _authenticatedRouteMixin, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _errorHandler.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    queryParams: {
      section: '',
      token: ''
    },

    routeUser: function routeUser() {
      var userRoute = this.get('user').getUserRoute();
      var new_location = localStorage.getItem('requestUrl');

      if (new_location == null && !this.get('router.url').includes('section=')) {
        if (userRoute !== 'user.edit') this.transitionTo(userRoute);
      }
      if (new_location != null && this.get('user.user') && !this.get('router.url').includes('section=')) {
        var _new_location = localStorage.getItem('requestUrl');
        localStorage.removeItem('requestUrl');
        window.location.replace(_new_location);
      };
      // if( !this.get('user.user.subscribed') ) this.transitionTo('user.billing');
    },
    beforeModel: function beforeModel(transition) {
      if (this.get('session.isAuthenticated') && !this.get('user.user')) {
        var new_location = localStorage.getItem('requestUrl');
        if (new_location != null) {

          localStorage.removeItem('requestUrl');
          window.location.replace(new_location);
        } else {
          location.reload();
        }
      } else {
        this.routeUser();
      }
    },
    model: function model() {
      return this.modelFor('application');
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super.apply(this, arguments);

      model.get('workExperiences').then(function (we) {
        if (!we.get('length')) {
          var blankWorkExp = _this.get('store').createRecord('workExperience', {
            user: model,
            descriptions: [],
            achievements: []
          });

          // this.get('store').createRecord('achievement', {
          //   workExperience: blankWorkExp,
          // });
        }
      });

      model.get('education').then(function (ed) {
        if (!ed.get('length')) {
          _this.get('store').createRecord('education', {
            user: model,
            descriptions: [],
            achievements: []
          });
        }
      });
    },


    actions: {
      saveUser: function saveUser() {
        var _this2 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users', 'PUT', { user: {
            name: this.get('controller.model.name'),
            industry: this.get('controller.model.industry'),
            image: this.get('controller.model.image')
          } }).then(function (user) {
          _this2.get('store').pushPayload(user);
          Ember.get(_this2, 'flashMessages').success('User successfully updated');
        }).catch(function (errors) {
          _this2.handleError(errors);
        });
      },
      addItem: function addItem(model, user) {
        this.get('store').createRecord(model, {
          user: user
        });
      },
      openWorkExperienceHelpModal: function openWorkExperienceHelpModal() {
        this.set('controller.isWorkExperienceHelpModalOpen', true);
      },
      openEducationHelpModal: function openEducationHelpModal() {
        this.set('controller.isEducationHelpModalOpen', true);
      },
      openSubmitResumeModal: function openSubmitResumeModal() {
        this.set('controller.isSubmitResumeModalOpen', true);
      },
      reloadItems: function reloadItems() {
        this.get('model').reload();
      }
    }
  });
});