define('resource-verified-ember/components/admin/add-institution-modal', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['add-institution-modal'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    action: 'POST',
    urlID: '',
    applicantsAccepted: false,
    institutionLocations: Ember.A(),

    getUsers: function getUsers() {
      var _this = this;

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + this.get('urlID') + '/users', 'GET').then(function (users) {
        _this.set('users', users.users);
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    },
    getLocations: function getLocations() {
      var _this2 = this;

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + this.get('urlID') + '/locations', 'GET').then(function (locations) {
        var formattedLocations = Ember.A();

        locations.locations.forEach(function (location) {
          formattedLocations.pushObject({ id: location.id, value: location.city + ', ' + location.state + ', ' + location.country, city: location.city, state: location.state, country: location.country });
        });

        _this2.set('institutionLocations', formattedLocations);
      }).catch(function (errors) {
        _this2.handleError(errors);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('institution')) {
        this.set('institutionName', this.get('institution.name')), this.set('institutionType', this.get('institution.institution_type')), this.set('institutionLocation', this.get('institution.location')), this.set('institutionUserLimit', this.get('institution.user_limit')), this.set('applicantsAccepted', this.get('institution.accepts_applicant_requests')), this.set('employeeDashboardEnabled', this.get('institution.employee_dashboard_enabled')), this.set('clientDashboardEnabled', this.get('institution.client_dashboard_enabled')), this.set('vendorDashboardEnabled', this.get('institution.vendor_dashboard_enabled')), this.set('action', 'PUT');
        this.set('urlID', '/' + this.get('institution.id'));
        this.getLocations();
      }

      this.getUsers();
    },
    close: function close() {
      this._super.apply(this, arguments);
      this.set('institution', null);
      this.set('institutionLocations', {});
    },


    filteredUsers: Ember.computed.filterBy('users', 'disabled', false),

    saveLocations: function saveLocations(id) {
      var _this3 = this;

      var urlID = '/' + id,
          allLocations = this.get('institutionLocations'),
          requestObj = [];
      allLocations.forEach(function (location) {
        if (location.id > 0) {
          // If has ID, update it (PUT)
          requestObj.push(_this3.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + urlID + '/locations/' + location.id, 'PUT', { location: location }));
        } else {
          // If no ID, add it (POST)
          requestObj.push(_this3.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + urlID + '/locations', 'POST', { location: location }));
        }
      });
      return requestObj;
    },


    actions: {
      updateLocations: function updateLocations(action) {
        var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var id = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        var locations = this.get('institutionLocations');
        if (action === "add") {
          locations.pushObject({ id: '', value: '', city: '', state: '', country: '' });
        } else {
          locations.popObject(index);
          // If has a location ID, send delete request to endpoint
          if (id > 0) {
            this.send('deleteLocation', id);
          }
        }
        this.set('institutionLocations', locations);
      },


      // TODO: abstract this; used in verifiable-item.js also
      setLocation: function setLocation(index, arrAddress) {
        var itemLocality = '',
            itemState = '',
            itemCountry = '',
            allLocations = this.get('institutionLocations'),
            location = allLocations.objectAt(index);

        // iterate through address_component array
        arrAddress.address_components.forEach(function (address_component) {
          if (address_component.types[0] == 'locality') {
            itemLocality = address_component.long_name;
            location.city = itemLocality;
          }
          if (address_component.types[0] == 'administrative_area_level_1') {
            itemState = address_component.long_name;
            location.state = itemState;
          }
          if (address_component.types[0] == 'country') {
            itemCountry = address_component.long_name;
            location.country = itemCountry;
          }
        });

        allLocations[index] = location;
        this.set('institutionLocations', allLocations);
      },
      saveCompany: function saveCompany() {
        var _this4 = this;

        var institutionObj = {
          name: this.get('institutionName'),
          institution_type: this.get('institutionType'),
          user_limit: this.get('institutionUserLimit'),
          accepts_applicant_requests: this.get('applicantsAccepted'),
          employee_dashboard_enabled: this.get('employeeDashboardEnabled'),
          client_dashboard_enabled: this.get('clientDashboardEnabled'),
          vendor_dashboard_enabled: this.get('vendorDashboardEnabled')
        };

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + this.get('urlID'), this.get('action'), { institution: institutionObj }).then(function (institution) {
          var requestObj = _this4.saveLocations(institution.institution.id);
          Ember.RSVP.all(requestObj).then(function () {
            _this4.sendAction('reload');
            _this4.get('flashMessages').success('Institution added');
            _this4.close();
          }).catch(function (errors) {
            _this4.get('flashMessages').danger('Could not save institution locations.');
            _this4.handleError(errors);
          });
        }).catch(function (errors) {
          _this4.handleError(errors);
        });
      },
      deleteUser: function deleteUser(user) {
        var _this5 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + this.get('urlID') + '/users/' + user.id, 'DELETE').then(function (user) {
          _this5.getUsers();
        }).catch(function (errors) {
          _this5.handleError(errors);
        });
      },
      deleteLocation: function deleteLocation(id) {
        var _this6 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/institutions' + this.get('urlID') + '/locations/' + id, 'DELETE', {}).catch(function (errors) {
          _this6.handleError(errors);
        });
      }
    }

  });
});