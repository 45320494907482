define('resource-verified-ember/routes/verify', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/user'], function (exports, _emberGetConfig, _errorHandler, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, _user.default, {
    ajax: Ember.inject.service(),

    model: function model(params, transition) {
      if (!transition.queryParams.type || !transition.queryParams.item || !transition.queryParams.request || !params.id) {
        this.transitionTo('company');
      }

      this.set('type', transition.queryParams.type);
      this.set('itemID', transition.queryParams.item);
      this.set('request', transition.queryParams.request);

      var newUser = this.store.peekRecord('user', params.id);
      if (newUser) {
        this.store.unloadRecord(newUser);
      }
      this.store.unloadAll('workExperience');
      this.store.unloadAll('education');
      this.store.unloadAll('achievement');

      return Ember.RSVP.hash({
        user: this.store.findRecord('user', params.id),
        item: this.store.queryRecord(transition.queryParams.type, { itemID: this.get('itemID'), userID: params.id })
      }).then(function (hash) {
        return hash;
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('imageSource', this.imageSource(model.user));
      controller.set('type', this.get('type'));
      controller.set('itemID', this.get('itemID'));

      if (!model.item) {
        var a = this.store.peekRecord('achievement', this.get('itemID'));

        if (a.get('workExperience.company_name')) {
          controller.set('item', a.get('workExperience'));
        } else {
          controller.set('item', a.get('education'));
        }
      } else {
        controller.set('item', model.item);
      }
    },


    actions: {
      showNewMessage: function showNewMessage() {
        this.set('controller.isNewMessageShown', true);
      },
      verifyItem: function verifyItem(action) {
        var _this = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/verification_requests/' + this.get('request') + '/' + action, 'POST').then(function () {
          var text = action == 'approve' ? 'accepted' : 'rejected';
          _this.get('flashMessages').success('Verification ' + text + '.');
          _this.transitionTo('company');
        }).catch(function (errors) {
          _this.handleError(errors);
          _this.transitionTo('company');
        });
      }
    }
  });
});