define('resource-verified-ember/controllers/dashboard', ['exports', 'moment', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _moment, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),

    dashboardTitle: Ember.computed('model.institution', 'dashboardType', function () {
      var institutionName = this.get('model.institution.name');
      var type = this.get('dashboardType').capitalize();
      return institutionName + ' - ' + type + ' Dashboard';
    }),

    addUserType: Ember.computed('dashboardType', function () {
      var type = this.get('dashboardType').capitalize();
      return 'Professional User (' + type + ')';
    }),

    actions: {
      refreshDashboard: function refreshDashboard() {
        this.send('reloadRoute');
      },
      showAddUserModal: function showAddUserModal() {
        this.set('isAddUserModalShown', true);
      }
    }
  });
});