define('resource-verified-ember/models/applicant-request', ['exports', 'ember-data', 'ember-get-config'], function (exports, _emberData, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    ajax: Ember.inject.service(),

    job_id: _emberData.default.attr('string'),
    job_role: _emberData.default.attr('string'),
    background_check_requested_at: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('string'),
    updated_at: _emberData.default.attr('string'),

    user: _emberData.default.attr({}),
    institution: _emberData.default.attr({}),
    location: _emberData.default.attr([]),

    background_checks: Ember.computed('user', function () {
      var _this = this;

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + user.id + '/background_checks', 'GET').then(function (results) {
        return results;
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    })
  });
});