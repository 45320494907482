define('resource-verified-ember/components/verify/verify-list-item', ['exports', 'resource-verified-ember/mixins/user'], function (exports, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_user.default, {
    tagName: 'li',

    image: Ember.computed('result.image', function () {
      return this.imageSource(this.get('verification.user'));
    })
  });
});