define('resource-verified-ember/models/institution', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    institution_type: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    user_count: _emberData.default.attr('number'),
    user_limit: _emberData.default.attr('number'),
    accepts_applicant_requests: _emberData.default.attr('boolean', { defaultValue: false }),
    employee_dashboard_enabled: _emberData.default.attr('boolean', { defaultValue: false }),
    client_dashboard_enabled: _emberData.default.attr('boolean', { defaultValue: false }),
    vendor_dashboard_enabled: _emberData.default.attr('boolean', { defaultValue: false }),
    locations: _emberData.default.attr({})
  });
});