define('resource-verified-ember/components/company-list-item', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/user'], function (exports, _emberGetConfig, _errorHandler, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _user.default, {
    ajax: Ember.inject.service(),
    preloader: Ember.inject.service(),
    userService: Ember.inject.service('user'),

    tagName: 'li',

    isEditable: Ember.computed('userService.user', 'user', function () {
      var isCurrentUser = +this.get('userService.user.id') === +this.get('user.id');
      return isCurrentUser && this.get('userService.isFreeVerifier') && !this.get('userService.hasProfile');
    }),

    canCreateAccount: Ember.computed('userService.user', 'isEditable', function () {
      return this.get('isEditable') && !this.get('userService.hasProfile');
    }),

    image: Ember.computed('user.image', function () {
      return this.imageSource(this.get('user'));
    }),

    actions: {
      saveUser: function saveUser() {
        var _this = this;

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users', 'PUT', { user: {
            name: this.get('user.name'),
            title: this.get('user.title'),
            image: this.get('user.image')
          } }).then(function () {
          Ember.get(_this, 'flashMessages').success('User successfully updated');
        }).catch(function (errors) {
          _this.handleError(errors);
        }).finally(function () {
          return _this.set('preloader.saving');
        });
      },
      showCreateAccountModal: function showCreateAccountModal(u) {
        this.sendAction('showCreateAccountModal', u);
      }
    }
  });
});