define('resource-verified-ember/components/add-user', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    preloader: Ember.inject.service(),

    classNames: ['add-user-modal'],

    verifierAccountType: 'free',
    companyAdmin: false,
    userTypeOptions: Ember.computed('selectedInstitution', function () {
      var options = ['Professional User (Employee)', 'Verifier'];
      var institution = this.get('selectedInstitution');

      if (!institution) {
        return options;
      }

      if (institution.client_dashboard_enabled) {
        options.push('Professional User (Client)');
      }

      if (institution.vendor_dashboard_enabled) {
        options.push('Professional User (Vendor)');
      }

      return options;
    }),

    actions: {
      updateInstitution: function updateInstitution(i) {
        this.set('selectedInstitution', i);
      },
      updateUserType: function updateUserType(t) {
        this.set('selectedUserType', t);
      },
      submit: function submit() {
        var _this = this;

        if (!this.get('userName') || !this.get('userEmail') || !this.get('selectedInstitution') || !this.get('selectedUserType')) {
          this.get('flashMessages').danger('Required field(s) missing');
          return false;
        }

        var url = '';

        if (this.get('companyAdmin')) {
          url = _emberGetConfig.default.apiFullPath + '/verifier/users';
        } else {
          url = _emberGetConfig.default.apiFullPath + '/admin/institutions/' + this.get('selectedInstitution.id') + '/users';
        }

        var type = 'user';
        switch (this.get('selectedUserType')) {
          case 'Professional User (Employee)':
            type = 'user';
            break;
          case 'Professional User (Client)':
            type = 'client';
            break;
          case 'Professional User (Vendor)':
            type = 'vendor';
            break;
          case 'Verifier':
            type = 'verifier';
            break;
        }

        this.set('preloader.saving', true);
        this.get('ajax').request(url, 'POST', {
          user: {
            name: this.get('userName'),
            email: this.get('userEmail'),
            admin: this.get('userAdmin'),
            premium: this.get('verifierAccountType') === 'premium',
            type: type
          }
        }).then(function () {
          _this.get('flashMessages').success('User added');

          if (_this.get('institutions')) {
            var i = _this.get('institutions').findBy('id', _this.get('selectedInstitution.id'));
            i.set('user_count', i.get('user_count') + 1);
          }

          if (_this.get('completeAction')) _this.sendAction('completeAction');

          _this.close();
        }).catch(function (errors) {
          _this.handleError(errors);
        }).finally(function () {
          return _this.set('preloader.saving', false);
        });
      }
    }
  });
});