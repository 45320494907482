define('resource-verified-ember/mixins/location', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        formattedLocation: function formattedLocation(arrAddress) {
            var itemLocality = '',
                itemState = '',
                itemCountry = '';

            var locationObj = {};

            // iterate through address_component array
            $.each(arrAddress.address_components, function (i, address_component) {
                if (address_component.types[0] == 'locality') {
                    itemLocality = address_component.long_name;
                    locationObj.city = itemLocality;
                }
                if (address_component.types[0] == 'administrative_area_level_1') {
                    itemState = address_component.long_name;
                    locationObj.state = itemState;
                }
                if (address_component.types[0] == 'country') {
                    itemCountry = address_component.long_name;
                    locationObj.country = itemCountry;
                }
            });

            return locationObj;
        }
    });
});