define('resource-verified-ember/components/verify/verifier-bgcheck', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['background-check-select'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    bgCheckOptions: [{ code: 'us_criminal_check', name: 'US Criminal Check' }, { code: 'international_criminal_check', name: 'International Criminal Check' }, { code: 'bankruptcies_liens_judgements', name: 'Bankruptcies, Liens and Judgements Check' }, { code: 'sanctions_watchlists_check', name: 'Sanctions, Watchlists, PEP, Adverse Media and SEC Action (US Only) Check' }],
    // isUSCriminalCheck : Ember.computed.equal('selectedBgCheck', 'us_criminal_check'),
    // isInternationalCriminalCheck: Ember.computed.equal('selectedBgCheck', 'international_criminal_check'),
    // isBankruptciesLiensCheck: Ember.computed.equal('selectedBgCheck', 'bankruptcies_liens_judgements'),
    isSanctionsWatchlistsCheck: Ember.computed.equal('selectedBgCheck.code', 'sanctions_watchlists_check'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedBgCheck', this.get('bgCheckOptions')[0]);
    },


    actions: {
      updateBgCheck: function updateBgCheck(value) {
        this.set('selectedBgCheck', value);
      },
      setDOB: function setDOB(newDOB) {
        this.set('dob', newDOB);
      },
      requestBackgroundCheck: function requestBackgroundCheck() {
        var _this = this;

        var applicantId = this.get('applicantId');
        var applicants = this.get('applicants');
        var dob = this.get('dob');
        var country = this.get('country');

        if (this.get('isSanctionsWatchlistsCheck')) {
          if (!dob) {
            this.handleError({ errors: { message: 'Date of Birth is required' } });
            return false;
          }

          if (!country) {
            this.handleError({ errors: { message: 'Country is required' } });
            return false;
          }
        }
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/applicant_requests/' + applicantId + '/request_background_check', 'POST', {
          report_type: this.get('selectedBgCheck').code,
          dob: dob, country: country
        }).then(function (response) {
          Ember.get(_this, 'flashMessages').success("Background check request has been submitted.");
          var updatedApplicants = applicants.map(function (applicant) {
            var a = applicant;
            if (applicant.id === applicantId) a = response.applicant_request;

            var applicantImage = a.user.image;
            if (applicantImage && applicantImage.indexOf('http') === -1) a.user.image = 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + applicantImage;
            return a;
          });
          _this.set('applicants', updatedApplicants);
          _this.close();
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this, 'flashMessages').danger(message);
        });
      }
    }

  });
});