define('resource-verified-ember/components/user-file-uploader-modal', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    cloudinarySignatureEndpoint: _emberGetConfig.default.apiFullPath + '/uploads/sign',
    files: [],
    path: '',

    classNames: ['add-file-uploader-modal'],

    getFiles: function getFiles() {
      var _this = this;

      this.get('ajax').request(this.get('path'), 'GET').then(function (documents) {
        _this.set('files', documents.documents);
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      var modelName = this.get('item.constructor.modelName'),
          share = '';

      if (this.get('share')) share = this.get('share');

      switch (modelName) {
        case 'work-experience':
          this.set('path', _emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/work_experiences/' + this.get('item.id') + '/documents' + share);
          break;
        case 'education':
          this.set('path', _emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/education/' + this.get('item.id') + '/documents' + share);
          break;
        case 'achievement':
          var parentType = 'work_experiences';
          if (this.get('parent.constructor.modelName') === 'education') parentType = education;
          this.set('path', _emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/' + parentType + '/' + this.get('parent.id') + '/achievements/' + this.get('item.id') + '/documents' + share);
          break;
      }

      this.getFiles();
    },


    actions: {
      userFileUploadComplete: function userFileUploadComplete(e, data) {
        var _this2 = this;

        this.get('ajax').request(this.get('path'), 'POST', { document: { file_location: data.result.public_id } }).then(function () {
          _this2.getFiles();
          _this2.get('user.workExperiences').reload();
          _this2.get('user.education').reload();
        }).catch(function (errors) {
          _this2.handleError(errors);
        });
      },
      userFileUploadFailure: function userFileUploadFailure(e, data) {
        this.get('flashMessages').danger('File upload failure. Please try again.');
      },
      deleteFile: function deleteFile(f) {
        this.get('files').removeObject(f);
      }
    }
  });
});