define('resource-verified-ember/components/cloudinary-direct-file', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    tagName: 'input',
    classNames: ['cloudinary-fileupload'],

    attributeBindings: ['name', 'type', 'data-cloudinary-field', 'data-form-data', 'multiple', 'style', 'accept'],

    // Attributes
    name: 'file',
    type: 'file',
    multiple: false,
    fieldName: null,
    'data-cloudinary-field': Ember.computed.alias('fieldName'),
    accept: 'image/jpeg,image/gif,image/png,image/bmp,document/pdf',
    style: Ember.String.htmlSafe(""),

    // Endpoint
    signatureEndpoint: null,

    // Default Options
    disableImageResize: false,
    imageMaxWidth: 10000000,
    imageMaxHeight: 10000000,
    acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|pdf)$/i,
    maxFileSize: 50000000,
    loadImageMaxFileSize: 50000000,

    // Fetch signature
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!this.get('signatureEndpoint')) {
        Ember.Logger.error('`signatureEndpoint` parameter must be specified on cloudinary-direct-file component.');
      }

      // Ember.$.get(this.get('signatureEndpoint'), { timestamp: Date.now() / 1000 }).done((response) => {
      //   Ember.run(() => { this.set('data-form-data', JSON.stringify(response)); });
      // });

      // AJR: copied this whole thing just to get an authed request to sign the upload >.<
      this.get('ajax').request(this.get('signatureEndpoint'), 'GET', { timestamp: Date.now() / 1000 }, false).then(function (response) {
        Ember.run(function () {
          _this.set('data-form-data', JSON.stringify(response));
        });
      });
    },


    didSetData: Ember.observer('data-form-data', function () {
      Ember.run.next(this, function () {
        this.$().cloudinary_fileupload({
          disableImageResize: this.get('disableImageResize'),
          imageMaxWidth: this.get('imageMaxWidth'),
          imageMaxHeight: this.get('imageMaxHeight'),
          acceptFileTypes: this.get('acceptFileTypes'),
          maxFileSize: this.get('maxFileSize'),
          loadImageMaxFileSize: this.get('loadImageMaxFileSize')
        });
      });
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.$().bind('cloudinarydone', function (e, data) {
        _this2.sendAction('onUploadDone', e, data);
      });

      this.$().bind('cloudinaryprogress', function (e, data) {
        _this2.sendAction('fileProgress', e, data);
      });

      this.$().bind('cloudinaryprogressall', function (e, data) {
        _this2.sendAction('allFileProgress', e, data);
      });

      this.$().bind('cloudinarystart', function (e, data) {
        _this2.sendAction('onUploadStart', e, data);
      });

      this.$().bind('cloudinarystop', function (e, data) {
        _this2.sendAction('onUploadStop', e, data);
      });

      this.$().bind('cloudinaryfail', function (e, data) {
        _this2.sendAction('onUploadFail', e, data);
      });

      this.$().bind('fileuploadprocessfail', function (e, data) {
        _this2.sendAction('onUploadFail', e, data);
      });

      this.$().bind('cloudinaryalways', function (e, data) {
        _this2.sendAction('onUploadAlways', e, data);
      });
    }
  });
});