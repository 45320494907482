define('resource-verified-ember/routes/user/messages/deleted', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        user: this.modelFor('application'),
        messages: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages?type=deleted', 'GET').then(function (messages) {
          return messages.messages;
        }).catch(function (errors) {
          _this.handleError(errors);
        })
      }).then(function (hash) {
        return hash;
      });
    }
  });
});