define('resource-verified-ember/services/ajax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service('session'),

    request: function request(endpoint, method, data) {
      var _this = this;

      var jsonify = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;

      if (jsonify) data = JSON.stringify(data);

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var self = _this;
        Ember.$.ajax(endpoint, {
          type: method,
          data: data,
          contentType: "application/json",
          beforeSend: function beforeSend(xhr) {
            self.get('session').authorize('authorizer:application', function (headerName, headerValue) {
              return xhr.setRequestHeader(headerName, headerValue);
            });
          }
        }).then(function (data) {
          resolve(data);
        }, function (error) {
          reject(error.responseJSON);
        });
      });
    },
    requestDownload: function requestDownload(endpoint, method, filename) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var self = _this2;

        Ember.$.ajax({
          type: method,
          url: endpoint,
          beforeSend: function beforeSend(xhr) {
            self.get('session').authorize('authorizer:application', function (headerName, headerValue) {
              return xhr.setRequestHeader(headerName, headerValue);
            });
          },

          success: function success(response, status, xhr) {
            var type = xhr.getResponseHeader('Content-Type');
            var blob = new Blob([response], { type: type });

            if (typeof window.navigator.msSaveBlob !== 'undefined') {
              window.navigator.msSaveBlob(blob, filename);
            } else {
              var URL = window.URL || window.webkitURL;
              var downloadUrl = URL.createObjectURL(blob);

              if (filename) {
                var a = document.createElement("a");
                if (typeof a.download === 'undefined') {
                  window.location = downloadUrl;
                } else {
                  a.href = downloadUrl;
                  a.download = filename;
                  document.body.appendChild(a);
                  a.click();
                }
              } else {
                window.location = downloadUrl;
              }

              resolve(true);

              setTimeout(function () {
                URL.revokeObjectURL(downloadUrl);
              }, 100);
            }
          }
        });
      });
    }
  });
});