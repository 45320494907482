define('resource-verified-ember/components/user/billing-card', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_errorHandler.default, {
        ajax: Ember.inject.service(),

        didInsertElement: function didInsertElement() {
            if (this.get('requireCard') && !this.get('hasCard')) {
                this.set('stripe', Stripe(_emberGetConfig.default.stripe.publishableKey));
                var elements = this.get('stripe').elements();
                this.set('card', elements.create('card', {
                    hidePostalCode: true,
                    style: {
                        base: {
                            fontSize: '14px',
                            fontFamily: '"Lato", sans-serif'
                        }
                    }
                }));
                this.get('card').mount('#card-element');
            }
        },
        isAddonSelected: function isAddonSelected(key) {
            var found = false,
                extraOptions = this.get('extraOptions');

            for (var i = 0; i < extraOptions.length; i++) {
                if (extraOptions[i].value === key) {
                    return found = true;
                }
            }
            return found;
        },


        actions: {
            submit: function submit() {
                var _this = this;

                if (!this.get('requireCard')) {
                    this.sendAction('action', null, null);
                    return;
                }

                if (this.get('hasCard')) {
                    this.sendAction('action', null, this.get('couponCode'));
                    return;
                }

                if (!this.get('nameOnCard')) {
                    this.handleError({ errors: { message: 'Name on Card is required' } });
                    return false;
                }

                this.get('stripe').createToken(this.get('card'), { name: this.get('nameOnCard') }).then(function (result) {
                    if (result.error) {
                        _this.handleError(result.error);
                        // var errorElement = document.getElementById('card-errors');
                        // errorElement.textContent = result.error.message;
                    } else {
                        _this.sendAction('action', result.token.id, _this.get('couponCode'));
                        // this.get('ajax').request(`${ENV.apiFullPath}/billing`, 'POST',
                        //       {
                        //         stripe_token  : result.token.id,
                        //         access_type   : this.get('accessType'),
                        //         addons        : {
                        //           criminal_check    : this.isAddonSelected('criminal_check'),
                        //           credit_report     : this.isAddonSelected('credit_report'),
                        //           criminal_research : this.isAddonSelected('criminal_research'),
                        //           bankruptcy_filing : this.isAddonSelected('bankruptcy_filing')
                        //         }
                        //       }
                        //     ).then(() => {
                        //       window.location.href = '/';
                        //     }).catch((errors) => {
                        //       this.handleError(errors);
                        //     });
                    }
                });
            }
        }
    });
});