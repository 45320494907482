define('resource-verified-ember/components/dashboard/bg-check', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['background-check-select'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    bgCheckOptions: [{ code: 'us_criminal_check', name: 'US Criminal Check' }, { code: 'international_criminal_check', name: 'International Criminal Check' }, { code: 'bankruptcies_liens_judgements', name: 'Bankruptcies, Liens and Judgements Check' }, { code: 'sanctions_watchlists_check', name: 'Sanctions, Watchlists, PEP, Adverse Media and SEC Action (US Only) Check' }],
    // isUSCriminalCheck : Ember.computed.equal('selectedBgCheck', 'us_criminal_check'),
    // isInternationalCriminalCheck: Ember.computed.equal('selectedBgCheck', 'international_criminal_check'),
    // isBankruptciesLiensCheck: Ember.computed.equal('selectedBgCheck', 'bankruptcies_liens_judgements'),
    isSanctionsWatchlistsCheck: Ember.computed.equal('selectedBgCheck.code', 'sanctions_watchlists_check'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('usCriminalCheck', false);
      this.set('internationalCriminalCheck', false);
      this.set('bankruptciesLiensJudgements', false);
      this.set('sanctionsWatchlistsCheck', false);
    },


    actions: {
      requestBackgroundCheck: function requestBackgroundCheck() {
        var _this = this;

        var userId = this.get('user').id;
        var dashboardType = this.get('dashboardType');

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/dashboard/request_background_check?type=' + dashboardType, 'POST', {
          user_id: userId,
          us_criminal_check: this.get('usCriminalCheck'),
          international_criminal_check: this.get('internationalCriminalCheck'),
          bankruptcies_liens_judgements: this.get('bankruptciesLiensJudgements'),
          sanctions_watchlists_check: this.get('sanctionsWatchlistsCheck')
        }).then(function (response) {
          Ember.get(_this, 'flashMessages').success("Background check request has been submitted.");
          _this.close();
          _this.onComplete();
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this, 'flashMessages').danger(message);
        });
      }
    }

  });
});