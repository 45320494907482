define('resource-verified-ember/authorizers/application', ['exports', 'ember-simple-auth/authorizers/devise'], function (exports, _devise) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    authorize: function authorize(data, header) {
      var userToken = data.token;
      var userClient = data.client;
      var userUid = data.uid;

      if (!Ember.isEmpty(userToken) && !Ember.isEmpty(userClient) && !Ember.isEmpty(userUid)) {
        header('access-token', userToken);
        header('client', userClient);
        header('uid', userUid);
      }
    }
  });
});