define('resource-verified-ember/components/verify/verify-item', ['exports', 'resource-verified-ember/mixins/verification-item'], function (exports, _verificationItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_verificationItem.default, {
    classNames: ['verification-item'],

    actions: {
      toggleVerifierDisplay: function toggleVerifierDisplay() {
        this.set('isVerifierDisplayShown', !this.get('isVerifierDisplayShown'));
      },
      toggleAchievementVerifierDisplay: function toggleAchievementVerifierDisplay(achievement) {
        achievement.set('isVerifierDisplayShown', !achievement.get('isVerifierDisplayShown'));
      },
      verify: function verify(action) {
        this.sendAction('action', action);
      }
    }
  });
});