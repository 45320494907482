define('resource-verified-ember/routes/search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    search: Ember.inject.service(),
    user: Ember.inject.service(),

    model: function model() {
      return this.modelFor('application');
    },
    afterModel: function afterModel() {
      // if( !this.get('user.user') ) this.set('user.user', this.store.queryRecord('user', {}));
      if (!this.get('user.canSearch')) this.transitionTo('user.edit');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('search', this.get('search'));

      var isPremiumVerifier = model.get('role') === 'verifier' && model.get('premium');
      var isAdmin = model.get('role') === 'admin';
      controller.set('showSearchBar', isPremiumVerifier || isAdmin);

      // if( this.get('search.results') ) {
      //   controller.set('results', this.get('search.results'));
      //   this.get('search').clearCachedResults();
      // }
    }
  });
});