define('resource-verified-ember/controllers/user/public', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    userService: Ember.inject.service('user'),
    session: Ember.inject.service(),

    canMessage: Ember.computed('userService.user', 'user', 'session.isAuthenticated', function () {
      return this.get('session.isAuthenticated') && this.get('userService.user.id') && this.get('userService.user.id') != this.get('user.id');
    }),

    canDisable: Ember.computed('userService.user', 'user', 'session.isAuthenticated', function () {
      return this.get('session.isAuthenticated') && this.get('userService.user.id') && this.get('userService.user.role') == 'admin' && !this.get('user.disabled');
    })

  });
});