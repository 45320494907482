define('resource-verified-ember/routes/dashboard', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    verifierResponse: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session.data.authenticated.role') !== 'verifier') {
        this.transitionTo('/');
      }

      if (this.get('session.isAuthenticated') && !this.get('user.user')) {
        location.reload();
      }
    },
    model: function model(params) {
      var _this = this;

      var dashboardType = params.type;
      this.controllerFor('dashboard').set('dashboardType', dashboardType);
      return Ember.RSVP.hash({
        user: this.modelFor('application'),
        institution: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/institutions', 'GET').then(function (institutions) {
          return institutions.institution;
        }).catch(function (errors) {
          _this.handleError(errors);
        }),
        settings: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/dashboard/settings?type=' + dashboardType, 'GET').then(function (settings) {
          console.log(settings);
          return settings.institution_dashboard;
        }).catch(function (errors) {
          debugger;
          _this.handleError(errors);
        }),
        users: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/dashboard?type=' + dashboardType, 'GET').then(function (users) {
          if (users.dashboard_users) {
            return users.dashboard_users.map(function (user) {
              var userImage = user.user.image;
              if (userImage && userImage.indexOf('http') === -1) user.user.image = 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + userImage;
              return user;
            });
          } else {
            return [];
          }
        }).catch(function (errors) {
          debugger;
          _this.handleError(errors);
        })
      });
    },
    afterModel: function afterModel(model) {
      var institution = model.institution;
      var dashboardType = this.controllerFor('dashboard').get('dashboardType');
      if (dashboardType == 'employee' && !institution.employee_dashboard_enabled || dashboardType == 'client' && !institution.client_dashboard_enabled || dashboardType == 'vendor' && !institution.vendor_dashboard_enabled) {
        this.get('flashMessages').danger('Please contact ResourceVerified for access to the ' + dashboardType + ' dashboard.');
        this.transitionTo('/');
      }
    },


    actions: {
      openBackgroundCheckModal: function openBackgroundCheckModal(user) {
        this.set('controller.bgCheckUser', user);
        this.set('controller.openBackgroundCheckModal', true);
      },
      openRecurringReportSettingsModal: function openRecurringReportSettingsModal() {
        this.set('controller.isRecurringReportSettingsModalShown', true);
      },
      reloadRoute: function reloadRoute() {
        this.refresh();
      }
    }

  });
});