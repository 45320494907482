define('resource-verified-ember/components/dashboard/recurring-report-settings', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['background-check-select'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    reportFrequencyOptions: [[0, 'Never'], [1, 'Monthly'], [3, 'Quarterly'], [12, 'Annually']],

    actions: {
      updateReportFrequency: function updateReportFrequency(reportType, frequency) {
        this.set('model.' + reportType + '_frequency', frequency);
      },
      saveRecurringReportSettings: function saveRecurringReportSettings() {
        var _this = this;

        var doSubmit = confirm('Background Check requests may be generated/sent at this time (if required). Continue?');

        if (!doSubmit) {
          return;
        }

        var dashboardType = this.get('dashboardType');
        var settings = this.get('model');
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/dashboard/update_settings?type=' + dashboardType, 'POST', {
          us_criminal_check_frequency: settings.us_criminal_check_frequency,
          international_criminal_check_frequency: settings.international_criminal_check_frequency,
          bankruptcies_liens_judgements_frequency: settings.bankruptcies_liens_judgements_frequency,
          sanctions_watchlists_check_frequency: settings.sanctions_watchlists_check_frequency
        }).then(function (response) {
          Ember.get(_this, 'flashMessages').success("Recurring report settings have been updated.");
          _this.close();
          _this.onSave();
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this, 'flashMessages').danger(message);
        });
      }
    }

  });
});