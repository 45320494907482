define('resource-verified-ember/components/messages/message-preview', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/user'], function (exports, _emberGetConfig, _errorHandler, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _user.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    classNames: ['message-preview'],
    classNameBindings: ['unread'],
    unread: Ember.computed('message.read', function () {
      return !this.get('message.read');
    }),

    sentMessage: Ember.computed('currentUser.id', 'message.sender.id', function () {
      return +this.get('currentUser.id') === +this.get('message.sender.id');
    }),

    image: Ember.computed('sentMessage', function () {
      if (this.get('sentMessage')) {
        return this.imageSource(this.get('message.recipient'));
      } else {
        return this.imageSource(this.get('message.sender'));
      }
    }),

    bodyPreview: Ember.computed('message.body', function () {
      var content = this.get('message.body');
      if (this.get('message.most_recent_reply')) content = this.get('message.most_recent_reply.body');

      if (content.length > 125) content = content.substr(0, 125) + '...';
      return content;
    }),

    showDelete: Ember.computed('type', function () {
      return this.get('type') !== 'deleted' && this.get('type') !== 'sent';
    }),

    actions: {
      showMessage: function showMessage() {
        this.set('isMessageShown', true);
      },
      delete: function _delete() {
        var _this = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages/' + this.get('message.id'), 'DELETE').then(function () {
          _this.get('messages').removeObject(_this.get('message'));
          _this.get('flashMessages').success('Message deleted');
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }
    }
  });
});