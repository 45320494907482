define('resource-verified-ember/components/user/verification-payment', ['exports', 'resource-verified-ember/mixins/modal'], function (exports, _modal) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modal.default, {
        billingSelection: 'per-verification',

        actions: {
            submit: function submit(stripeToken, couponCode) {
                this.sendAction('action', stripeToken, this.get('billingSelection'), couponCode);
                this.close();
            }
        }
    });
});