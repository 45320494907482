define('resource-verified-ember/services/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    user: null,
    justConfirmed: false,

    getUserRoute: function getUserRoute() {
      if (this.get('user')) {
        switch (this.get('user.role')) {
          case 'admin':
            return '/admin';
            break;
          case 'verifier':
            if (!this.get('user.profile_created')) return '/company';
            break;
          default:
            return 'user.edit';
            break;
        }
      }
    },
    sortVerificationItems: function sortVerificationItems(a, b) {
      // new items(being created) on top
      if (b.get('isNew')) {
        return 1;
      }

      // present
      if (!a.get('end_at') && !b.get('end_at')) {
        // both are present positions, sort by start date
        if (new Date(a.get('start_at')).getTime() > new Date(b.get('start_at')).getTime()) {
          return -1;
        }
        if (new Date(a.get('start_at')).getTime() < new Date(b.get('start_at')).getTime()) {
          return 1;
        }
      }
      if (!a.get('end_at')) {
        return -1;
      }
      if (!b.get('end_at')) {
        return 1;
      }

      // item has end date
      if (new Date(a.get('end_at')).getTime() > new Date(b.get('end_at')).getTime()) {
        return -1;
      }
      if (new Date(a.get('end_at')).getTime() < new Date(b.get('end_at')).getTime()) {
        return 1;
      }

      return 0;
    },


    isFreeVerifier: Ember.computed('user', function () {
      return this.get('user.role') === 'verifier' && !this.get('user.premium');
    }),

    hasProfile: Ember.computed('user', function () {
      return (this.get('user.role') === 'user' || this.get('user.role') === 'verifier') && this.get('user.profile_created');
    }),

    canSearch: Ember.computed('user', function () {
      if (this.get('user.role') === 'verifier' && !this.get('user.premium') && !this.get('user.admin')) return false;
      if (!this.get('user')) return false;
      return true;
    }),

    sortedWorkExperiences: Ember.computed.sort('user.workExperiences', function (a, b) {
      return this.sortVerificationItems(a, b);
    }),

    sortedEducation: Ember.computed.sort('user.education', function (a, b) {
      return this.sortVerificationItems(a, b);
    }),

    imageSource: Ember.computed('user', function () {
      var image = this.get('user.image');
      if (image) {
        if (image.indexOf('http') >= 0) {
          return image;
        } else {
          return 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + this.get('user.image');
        }
      } else {
        return '/images/default-avatar.jpg';
      }
    }),

    title: Ember.computed('sortedWorkExperiences', 'sortedEducation', function () {
      var savedWorkExperience = this.get('sortedWorkExperiences').rejectBy('isNew'),
          savedEducation = this.get('sortedEducation').rejectBy('isNew');

      if (savedWorkExperience.get('length')) {
        return savedWorkExperience.get('firstObject.title');
      } else if (savedEducation.get('length')) {
        return savedEducation.get('firstObject.title');
      }
    }),

    location: Ember.computed('sortedWorkExperiences', 'sortedEducation', function () {
      var savedWorkExperience = this.get('sortedWorkExperiences').rejectBy('isNew'),
          savedEducation = this.get('sortedEducation').rejectBy('isNew');

      if (savedWorkExperience.get('length')) {
        return savedWorkExperience.get('firstObject.location');
      } else if (savedEducation.get('length')) {
        return savedEducation.get('firstObject.location');
      }
    }),

    billingProfile: Ember.computed('user', 'user.billing_profile', function () {
      return this.get('user.billing_profile');
    }),

    userHasCard: Ember.computed('user', 'user.billing_profile', function () {
      var billing_profile = this.get('user.billing_profile');
      return billing_profile && billing_profile.card_brand && billing_profile.card_exp_month && billing_profile.card_exp_year;
    }),

    userPurchasedFullAccess: Ember.computed('user', 'user.billing_profile', 'user.paid_by_institution', function () {
      var billing_profile = this.get('user.billing_profile');
      var paid_by_institution = this.get('user.paid_by_institution');
      return billing_profile && billing_profile.purchased_full_access || paid_by_institution;
    })
  });
});