define('resource-verified-ember/routes/entry/password', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),

    queryParams: {
      action: {},
      token: {}
    },

    beforeModel: function beforeModel(transition) {
      this.set('queryParams.action', transition.queryParams.action);
      this.set('queryParams.token', transition.queryParams.token);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('token', this.get('queryParams.token'));

      var templateData = {
        heading: 'Reset Password',
        confirmMessage: 'Password reset; please log in.'
      };

      if (this.get('queryParams.action') === 'setup') {
        templateData = {
          heading: 'Set Password',
          confirmMessage: 'Password set; please log in.'
        };
      }

      controller.set('templateData', templateData);
    },


    actions: {
      requestPasswordReset: function requestPasswordReset() {
        var _this = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/auth/password', 'POST', { email: this.get('controller.email') }).then(function () {
          _this.get('flashMessages').success('Password reset email sent.');
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      },
      resetPassword: function resetPassword() {
        var _this2 = this;

        if (this.get('controller.password') === this.get('controller.passwordConfirmation')) {
          this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/auth/password', 'PUT', {
            password: this.get('controller.password'),
            password_confirmation: this.get('controller.passwordConfirmation'),
            reset_password_token: this.get('controller.token')
          }).then(function () {
            _this2.get('flashMessages').success(_this2.get('controller.templateData.confirmMessage'));
            _this2.set('controller.password', '');
            _this2.set('controller.passwordConfirmation', '');
            _this2.set('controller.token', '');
            _this2.transitionTo('entry/signin');
          }).catch(function (errors) {
            _this2.handleError(errors);
          });
        } else {
          this.handleError({ errors: { message: 'Password & Password confirmation don\'t match' } });
        }
      }
    }
  });
});