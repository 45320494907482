define('resource-verified-ember/components/user/education-editable', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    preloader: Ember.inject.service(),

    classNames: ['editable-profile-section'],
    classNameBindings: ['saved'],
    saved: Ember.computed.not('item.isNew'),
    currentAttendance: Ember.computed('item.end_at', function () {
      if (!this.get('item.end_at')) return true;
    }),
    notCurrentAttendance: Ember.computed.not('currentAttendance'),

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('item.achievements.length') && this.get('item.isNew')) {
        this.get('store').createRecord('achievement', {
          education: this.get('item')
        });
      }
    },


    actions: {
      setStartAt: function setStartAt(date) {
        this.set('item.start_at', date);
      },
      setEndAt: function setEndAt(date) {
        this.set('item.end_at', date);
      },
      addAchievement: function addAchievement() {
        this.get('store').createRecord('achievement', {
          education: this.get('item')
        });
      },
      saveEducation: function saveEducation() {
        var _this = this;

        this.set('preloader.saving', true);
        // if an end date was entered then the current box checked, null out the date
        if (this.get('currentAttendance')) this.set('item.end_at', null);

        var educationObj = {
          school_name: this.get('item.school_name'),
          degree: this.get('item.degree'),
          start_at: this.get('item.start_at'),
          end_at: this.get('item.end_at'),
          description: this.get('item.description'),
          achievements: []
        };

        if (!educationObj.school_name || !educationObj.degree || !educationObj.location || !educationObj.start_at) {
          this.set('preloader.saving', false);
          Ember.get(this, 'flashMessages').danger('Please submit all required information');
          return false;
        }

        this.get('item.achievements').forEach(function (a) {
          educationObj.achievements.pushObject({
            achievement: a.get('achievement')
          });
        });

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/education', 'POST', { education: educationObj }).then(function () {
          _this.get('user.education').reload().then(function () {
            // hack https://github.com/emberjs/data/issues/4972#issuecomment-312301105
            // should be able to remove this one https://github.com/emberjs/ember.js/pull/15604 makes it to a release
            _this.get('store')._removeFromIdMap(_this.get('item')._internalModel);
            _this.get('item').unloadRecord();
          });

          Ember.get(_this, 'flashMessages').success('Education successfully saved for verification');
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this, 'flashMessages').danger(message);
        }).finally(function () {
          return _this.set('preloader.saving', false);
        });
      }
    }
  });
});