define('resource-verified-ember/components/messages/new-message', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    // user          : Ember.inject.service(),

    classNames: ['new-message'],

    actions: {
      submitMessage: function submitMessage() {
        var _this = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages', 'POST', {
          message: {
            recipient_id: this.get('recipient.id'),
            body: this.get('newMessage')
          }
        }).then(function (message) {
          _this.get('flashMessages').success('Message sent');
          _this.set('newMessage', '');
          _this.close();
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }
    }
  });
});