define('resource-verified-ember/router', ['exports', 'resource-verified-ember/config/environment', 'ember-router-scroll'], function (exports, _environment, _emberRouterScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend(_emberRouterScroll.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('admin');
    this.route('company');

    this.route('entry/signin', { path: '/signin' });
    this.route('entry/signup', { path: '/signup' });
    this.route('entry/verify', { path: '/verify' });
    this.route('entry/password', { path: '/password' });
    this.route('entry/oauth', { path: '/oauth' });

    this.route('search');

    this.route('user/public', { path: '/users/:id' });
    this.route('user', function () {
      this.route('edit');
      this.route('billing');

      this.route('messages', function () {
        this.route('inbox', { path: '/' });
        this.route('sent');
        this.route('deleted');
        this.route('criminal-check');
      });
      this.route('account');
    });

    this.route('verify', { path: '/verify/:id' });
    this.route('about-us');
    this.route('terms');
    this.route('privacy-policy');
    this.route('schools');
    this.route('individuals');
    this.route('companies');
    this.route('applicants');
    this.route('dashboard', { path: '/dashboard/:type' });
  });

  exports.default = Router;
});