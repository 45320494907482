define('resource-verified-ember/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    flashMessages: Ember.inject.service(),
    preloader: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    isSignup: Ember.computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'entry/signup') {
        return true;
      }
      return false;
    }),

    useSigninLink: Ember.computed('currentRouteName', function () {
      /*
        Logged in users will be redirected to their "home" page
        Non-logged in should default to signin
      */
      return this.get('currentRouteName') === 'entry/signin' || this.get('currentRouteName') === 'entry/signup' || this.get('currentRouteName') === 'privacy-policy' || this.get('currentRouteName') === 'terms' || this.get('currentRouteName') === 'password';
    }),

    showProfessionalProfileLink: Ember.computed('user.user.role', 'user.user.profile_created', function () {
      if (this.get('user.user.role') === 'admin') {
        return false;
      }

      return this.get('user.user.profile_created');
    }),

    actions: {
      signOut: function signOut() {
        this.get('session').invalidate();
      }
    }
  });
});