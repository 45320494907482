define('resource-verified-ember/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    search: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      var _get = this.get('session'),
          session = _get.session;

      if (!session.isAuthenticated && this.get('router.url') != '/signin' && this.get('router.url').includes('section=')) {
        localStorage.setItem('requestUrl', this.get('router.url'));
      };
      if (!session.isAuthenticated && transition.targetName === 'index') {
        this.transitionTo('entry/signin');
      }
    },
    model: function model() {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', {});
      }
    },
    afterModel: function afterModel(model) {
      this.set('user.user', model);
    },


    sessionAuthenticated: function sessionAuthenticated() {
      switch (this.get('session.data.authenticated.role')) {
        case 'admin':
          this.transitionTo('/admin');
          break;
        case 'verifier':
          this.transitionTo('/company');
          break;
        default:
          if (this.get('user.justConfirmed')) {
            this.transitionTo('user.billing');
          } else {
            this.transitionTo('user.edit');
          }
          break;
      }
    },

    invalidateSession: function invalidateSession() {
      this.get('session').invalidate();
    },


    actions: {
      searchName: function searchName() {
        this.get('search').search('name=' + this.controller.searchName, this.transitionTo('search'));
      },
      searchID: function searchID() {
        this.set('search.showCriminalCheck', true);
        this.get('search').search('resource_verified_id=' + this.controller.searchID, this.transitionTo('search'), true);
      }
    }
  });
});