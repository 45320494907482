define('resource-verified-ember/controllers/user/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    showCompleteReportButton: Ember.computed('model', function () {
      return this.get('model.profile_addons.bankruptcy_filing') || this.get('model.profile_addons.credit_report') || this.get('model.profile_addons.criminal_check') || this.get('model.profile_addons.criminal_research');
    })
  });
});