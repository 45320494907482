define('resource-verified-ember/components/user/require-information-button', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    preloader: Ember.inject.service(),

    classNames: ['require-information'],
    isActionVisible: false,

    closeAction: function closeAction() {
      Ember.$(document).off('click.hideAction');
      this.set('isActionVisible', false);
    },


    repText: Ember.computed('item.constructor.modelName', function () {
      if (this.get('item.constructor.modelName') == 'education') {
        return "from the Registrar's office";
      } else {
        return 'of an HR Contact';
      }
    }),

    actions: {
      showAction: function showAction() {
        var _this = this;

        this.set('isActionVisible', true);

        Ember.run.later(function () {
          Ember.$(document).on('click.hideAction', function (e) {
            if (!$(e.target).hasClass('require-information-button') && !$(e.target).hasClass('email') && !$(e.target).hasClass('send')) {
              _this.closeAction();
            }
          });
        });
      },
      hideAction: function hideAction() {
        this.closeAction();
      },
      confirm: function confirm() {
        var _this2 = this;

        if (!this.get('name') || !this.get('title') || !this.get('email')) {
          this.get('flashMessages').danger('All fields are required');
          return false;
        }

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifications/create_request', 'POST', {
          verifiable_id: this.get('item.id'),
          verifiable_type: this.get('item.constructor.modelName').replace('-', '_'),
          verifier_name: this.get('name'),
          verifier_title: this.get('title'),
          verifier_email: this.get('email')
        }).then(function () {
          _this2.closeAction();
          _this2.set('item.verification_status', 'pending');
          _this2.get('flashMessages').success('Verifier requested');

          // switch (this.get('item.constructor.modelName')) {
          //   case 'work-experience':
          //     this.get('user.workExperiences').reload();
          //     break;
          //   default:
          //     this.get('user.education').reload();
          // }

          // quick fix; achievements makes this difficult to tell which one to reload
          _this2.get('user.workExperiences').reload();
          _this2.get('user.education').reload();
        }).catch(function (errors) {
          _this2.handleError(errors);
        }).finally(function () {
          return _this2.set('preloader.saving', false);
        });
      }
    }
  });
});