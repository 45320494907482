define('resource-verified-ember/components/user/create-account-modal', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['create-account-modal'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service('user'),

    name: '',
    email: '',
    password: '',
    confirmPassword: '',

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('user')) {
        this.set('name', this.get('user.name'));
        this.set('email', this.get('user.email'));
        this.set('originalEmail', this.get('user.email'));
      }
    },


    actions: {
      createAccount: function createAccount() {
        var _this = this;

        if (Ember.isEmpty(this.get('name'))) {
          Ember.get(this, 'flashMessages').danger('Name cannot be blank.');
          return;
        }

        if (Ember.isEmpty(this.get('email'))) {
          Ember.get(this, 'flashMessages').danger('Email cannot be blank.');
          return;
        }

        if (Ember.isEmpty(this.get('password'))) {
          Ember.get(this, 'flashMessages').danger('Password cannot be blank.');
          return;
        }

        if (Ember.isEmpty(this.get('confirmPassword'))) {
          Ember.get(this, 'flashMessages').danger('Confirm Password cannot be blank.');
          return;
        }

        if (this.get('password') != this.get('confirmPassword')) {
          Ember.get(this, 'flashMessages').danger('Password and Confirm Password must match.');
          return;
        }

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/create_account', 'POST', {
          user: {
            name: this.get('name'),
            email: this.get('email'),
            password: this.get('password')
          }
        }).then(function (user) {
          _this.get('store').pushPayload(user);
          _this.set('password', null);
          _this.set('confirmPassword', null);
          _this.set('userService.user', _this.get('store').queryRecord('user', {}));

          var message = 'Your account was created.';

          if (_this.get('email') != _this.get('originalEmail')) {
            message = message + ' Please confirm your email address';
          }

          _this.get('flashMessages').success(message);
          if (_this.get('completeAction')) _this.sendAction('completeAction');
          _this.close();
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }
    }

  });
});