define('resource-verified-ember/components/user/submit-payment', ['exports', 'resource-verified-ember/mixins/modal', 'resource-verified-ember/mixins/error-handler'], function (exports, _modal, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modal.default, _errorHandler.default, {
    actions: {
      submit: function submit(stripeToken, couponCode) {
        if (this.get('requireDOBAndCountry')) {
          if (!this.get('firstName')) {
            this.handleError({ errors: { message: 'First name is required' } });
            return false;
          }
          if (!this.get('lastName')) {
            this.handleError({ errors: { message: 'Last name is required' } });
            return false;
          }
          if (!this.get('middleName') && !this.get('isCertify')) {
            this.handleError({ errors: { message: 'Middle name is required' } });
            return false;
          }
          if (!this.get('dob')) {
            this.handleError({ errors: { message: 'Date of Birth is required' } });
            return false;
          }

          if (!this.get('country')) {
            this.handleError({ errors: { message: 'Country is required' } });
            return false;
          }
        }

        var data = {
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          middleName: this.get('middleName') || null,
          dob: this.get('dob'),
          country: this.get('country'),
          stripeToken: stripeToken, couponCode: couponCode
        };
        this.sendAction('action', data);
        this.close();
      },
      setDOB: function setDOB(newDOB) {
        this.set('dob', newDOB);
      }
    }
  });
});