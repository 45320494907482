define('resource-verified-ember/adapters/application', ['exports', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-get-config'], function (exports, _activeModelAdapter, _dataAdapterMixin, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    host: _emberGetConfig.default.apiHost,
    namespace: _emberGetConfig.default.apiNamespace,
    authorizer: 'authorizer:application'
  });
});