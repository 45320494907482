define('resource-verified-ember/routes/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    session: Ember.inject.service(),
    user: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (!this.get('session.isAuthenticated')) this.transitionTo('/');
    },
    model: function model() {
      if (this.get('session.isAuthenticated') && !this.get('user.user')) {
        return this.get('store').queryRecord('user', {});
      } else {
        return this.modelFor('application');
      }
    }
  });
});