define('resource-verified-ember/components/messages/view-message', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    user: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages/' + this.get('message.id'), 'GET').then(function (message) {
        _this.set('parentMessage', message.message);

        // mark as read
        _this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages/' + _this.get('message.id') + '/mark_read', 'POST').then(function () {
          _this.set('message.read', true);
        });

        // get replies
        _this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages/' + _this.get('message.id') + '/replies', 'GET').then(function (replies) {
          _this.set('replies', replies.messages);
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    },


    actions: {
      submitResponse: function submitResponse() {
        var _this2 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages/' + this.get('message.id') + '/replies', 'POST', {
          message: { body: this.get('response') }
        }).then(function (message) {
          _this2.get('flashMessages').success('Response submitted');
          _this2.get('replies').pushObject(message.message);
          _this2.set('response', '');
        }).catch(function (errors) {
          _this2.handleError(errors);
        });
      }
    }
  });
});