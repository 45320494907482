define('resource-verified-ember/components/search-bar', ['exports', 'resource-verified-ember/mixins/location'], function (exports, _location) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_location.default, {
    ajax: Ember.inject.service(),
    search: Ember.inject.service(),

    filters: [{ key: 'company', label: 'Company', value: '' }, { key: 'school', label: 'School', value: '' }, { key: 'title', label: 'Title', value: '' }, { key: 'location', label: 'Location', value: '' }, { key: 'industry', label: 'Industry', value: '' }, { key: 'education-level', label: 'Education Level', value: '' }, { key: 'verification', label: 'Verification +', value: '' }],
    selectedFilters: [],

    actions: {
      setLocation: function setLocation(arrAddress) {
        this.set('formattedLocation', this.formattedLocation(arrAddress));
      },
      search: function search() {
        var _this = this;

        var searchString = '';
        this.get('selectedFilters').forEach(function (v) {
          if (v.key === 'location') {
            var formattedLocation = _this.get('formattedLocation'),
                locationString = 'location[city]=' + formattedLocation.city + '&location[state]=' + formattedLocation.state + '&location[country]=' + formattedLocation.country;

            searchString = '' + searchString + locationString + '&';
          } else {
            searchString = '' + searchString + v.key + '=' + v.value + '&';
          }
        });

        this.get('search').search(searchString, this.sendAction('callback'));
      }
    }
  });
});