define('resource-verified-ember/components/admin/pending-verifier', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',

    approveDisabled: Ember.computed.not('selectedInstitution'),

    actions: {
      updateInstitution: function updateInstitution(i) {
        this.set('verifier.institution', i);
        this.set('selectedInstitution', i);
      },
      approve: function approve() {
        this.sendAction('approve', this.get('verifier'));
      },
      deny: function deny() {
        this.sendAction('deny', this.get('verifier'));
      }
    }
  });
});