define('resource-verified-ember/components/user/submit-resume-modal', ['exports', 'resource-verified-ember/mixins/modal', 'ember-get-config'], function (exports, _modal, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modal.default, {
    classNames: ['submit-resume-modal'],

    ajax: Ember.inject.service(),

    start: true,
    institutionId: null,
    locationId: null,
    results: Ember.A(),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/autocomplete?q=' + this.get('company') + '&type=company&accepts_applicant_requests=true', 'GET').then(function (results) {
        _this.set('results', results.institutions);
      }).catch(function (errors) {
        var message = errors.responseJSON.errors.message;
        Ember.get(_this, 'flashMessages').danger(message);
      });
    },


    actions: {
      nextStep: function nextStep(institution) {
        var _this2 = this;

        this.set('start', false);
        this.set('institutionId', institution.id);
        this.set('institution', institution);

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/locations?institution_id=' + institution.id, 'GET').then(function (locations) {
          _this2.set('institutionLocations', locations.locations);
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this2, 'flashMessages').danger(message);
        });
      },
      updateLocation: function updateLocation(location) {
        console.log(location);
        this.set('selectedLocation', location);
      },
      submitResume: function submitResume() {
        var _this3 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/applicant_requests', 'POST', {
          user_id: this.get('user.id'),
          institution_id: this.get('institutionId'),
          job_id: this.get('jobId'),
          job_role: this.get('jobRole'),
          location_id: this.get('selectedLocation.id')
        }).then(function () {
          Ember.get(_this3, 'flashMessages').success("Your resume has been submitted.");
          _this3.close();
        }).catch(function (errors) {
          var message = errors.responseJSON.errors.message;
          Ember.get(_this3, 'flashMessages').danger(message);
        });
      }
    }
  });
});