define('resource-verified-ember/routes/user/billing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('accessType', 'one_time');
      controller.set('selectedExtraOptions', []);

      var extraOptions = [{ value: 'criminal_check', name: 'Criminal Check (includes SSN and OFAC)  - $4/mth' }, { value: 'credit_report', name: '(Coming soon) Annual Credit Report - $1/month', isDisabled: true }, { value: 'criminal_research', name: 'Federal Criminal Records search  - $1/month' }, { value: 'bankruptcy_filing', name: 'Bankruptcy Filing Search - $1.50/month ' }];
      controller.set('extraOptions', extraOptions);
    }
  });
});