define('resource-verified-ember/components/user-file-uploader', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['user-file-uploader-button action'],
    cloudinarySignatureEndpoint: _emberGetConfig.default.apiFullPath + '/uploads/sign',

    actions: {
      showModal: function showModal() {
        this.set('isUploadModalShown', true);
      }
    }
  });
});