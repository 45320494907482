define('resource-verified-ember/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    image: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    resource_verified_id: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    institution_allows_applicants: _emberData.default.attr('boolean'),
    institution_has_employee_dashboard: _emberData.default.attr('boolean'),
    institution_has_client_dashboard: _emberData.default.attr('boolean'),
    institution_has_vendor_dashboard: _emberData.default.attr('boolean'),
    subscribed: _emberData.default.attr('boolean'),
    verified: _emberData.default.attr('boolean'),
    premium: _emberData.default.attr('boolean'),
    admin: _emberData.default.attr('boolean'),
    professional_user_role: _emberData.default.attr('string'),
    background_check: _emberData.default.attr('boolean'),
    disabled: _emberData.default.attr('boolean'),
    unread_message_count: _emberData.default.attr('number'),
    paid_by_institution: _emberData.default.attr('boolean'),
    profile_created: _emberData.default.attr('boolean'),
    profile_addons: _emberData.default.attr({}),
    workExperiences: _emberData.default.hasMany('work-experience', { async: true }),
    education: _emberData.default.hasMany('education', { async: true }),
    billing_profile: _emberData.default.attr({})
  });
});