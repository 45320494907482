define('resource-verified-ember/authenticators/oauth', ['exports', 'ember-get-config', 'ember-simple-auth/authenticators/base'], function (exports, _emberGetConfig, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    restore: function restore(data) {
      return Ember.RSVP.Promise.resolve(data);
    },
    authenticate: function authenticate(data) {
      return Ember.RSVP.Promise.resolve(data);
    }
  });
});