define('resource-verified-ember/routes/entry/signin', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _emberGetConfig, _errorHandler, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, _unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    verifierResponse: Ember.inject.service(),

    queryParams: {
      action: {},
      confirmation_token: {},
      email: {},
      password: {},
      reset_password_token: {},
      verification_request_id: {}
    },

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);

      if (transition.queryParams.confirmation_token) {
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/auth/confirmation?confirmation_token=' + transition.queryParams.confirmation_token, 'GET').then(function () {
          _this.get('flashMessages').success('Email confirmed, please log in.');
          _this.set('user.justConfirmed', true);
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }
      if (transition.queryParams.reset_password_token) {
        this.transitionTo('entry/password', { queryParams: { token: transition.queryParams.reset_password_token } });
      }

      if (transition.queryParams.email && transition.queryParams.password) {
        this.set('verifierResponse.requestID', transition.queryParams.verification_request_id);
        this.set('verifierResponse.action', transition.queryParams.action);

        this.get('session').authenticate('authenticator:application', transition.queryParams.email, transition.queryParams.password).catch(function (errors) {
          _this.handleError(errors);
        });
      }
    },


    actions: {
      authenticate: function authenticate() {
        var _this2 = this;

        var _get$getProperties = this.get('controller').getProperties('identification', 'userPassword'),
            identification = _get$getProperties.identification,
            userPassword = _get$getProperties.userPassword;

        this.get('session').authenticate('authenticator:application', identification, userPassword).catch(function (errors) {
          _this2.handleError(errors);
        });
      },
      authenticateOauth: function authenticateOauth(provider) {
        window.location.replace(_emberGetConfig.default.apiFullPath + '/auth/' + provider);
      }
    }
  });
});