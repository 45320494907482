define('resource-verified-ember/routes/user/messages/sent', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/messages?type=sent', 'GET').then(function (messages) {
        return messages.messages;
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    }
  });
});