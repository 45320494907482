define('resource-verified-ember/routes/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _authenticatedRouteMixin, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _errorHandler.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    user: Ember.inject.service(),

    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated') && !this.get('user.user')) {
        location.reload();
      }
    },
    model: function model() {
      var _this = this;

      return Ember.RSVP.hash({
        user: this.modelFor('application'),
        requests: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/verification_requests', 'GET').then(function (requests) {
          return requests.verification_requests;
        }).catch(function (errors) {
          _this.handleError(errors);
        }),
        institutions: this.store.findAll('institution'),
        coupons: this.store.findAll('coupon')
      }).then(function (hash) {
        _this.set('user.user', hash.user);
        return hash;
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('institutionType', 'company');
      // this.store.findAll('institution').then((institutions) => {
      //   controller.set('institutions', institutions);
      // })
    },


    actions: {
      approve: function approve(verifier) {
        var _this2 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/verification_requests/' + verifier.id + '/approve', 'POST', { institution_id: verifier.institution.id }).then(function () {
          _this2.get('controller.model.requests').removeObject(verifier);
          _this2.get('flashMessages').success('Verifier approved');
        }).catch(function (err) {
          _this2.get('flashMessages').danger('Verifier approval failed');
        });
      },
      deny: function deny(verifier) {
        var _this3 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/verification_requests/' + verifier.id + '/deny', 'POST').then(function () {
          _this3.get('controller.model.requests').removeObject(verifier);
          _this3.get('flashMessages').danger('Verifier denied');
        }).catch(function (err) {
          _this3.get('flashMessages').danger('Verifier denial failed');
        });
      },
      showAddUserModal: function showAddUserModal() {
        this.set('controller.isAddUserModalShown', true);
      },
      showCreateCompanyModal: function showCreateCompanyModal(i) {
        if (i) this.set('controller.modalInstitution', i);
        this.set('controller.isCreateCompanyModalShown', true);
      },
      showCreateCouponModal: function showCreateCouponModal(c) {
        if (c) this.set('controller.modalCoupon', c);
        this.set('controller.isCreateCouponModalShown', true);
      },
      exportUsers: function exportUsers() {
        var _this4 = this;

        this.get('ajax').requestDownload(_emberGetConfig.default.apiFullPath + '/admin/users', 'GET', 'RV_Users_' + +new Date() + '.csv').then(function (resp) {}).catch(function (err) {
          _this4.get('flashMessages').danger(err);
        });
      },
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});