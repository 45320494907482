define('resource-verified-ember/routes/company', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _authenticatedRouteMixin, _emberGetConfig, _errorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _errorHandler.default, {
        ajax: Ember.inject.service(),
        flashMessages: Ember.inject.service(),
        search: Ember.inject.service(),
        session: Ember.inject.service(),
        user: Ember.inject.service(),
        verifierResponse: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (this.get('session.data.authenticated.role') !== 'verifier') {
                this.transitionTo('/');
            }

            if (this.get('session.isAuthenticated') && !this.get('user.user')) {
                location.reload();
            }
        },
        model: function model() {
            var _this = this;

            return Ember.RSVP.hash({
                user: this.modelFor('application'),
                company: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/institutions', 'GET').then(function (institutions) {
                    return institutions.institution;
                }).catch(function (errors) {
                    _this.handleError(errors);
                }),
                verifiers: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/users', 'GET').then(function (users) {
                    return users.users;
                }).catch(function (errors) {
                    _this.handleError(errors);
                }),
                employees: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/institutions/employees?type=current', 'GET').then(function (employees) {
                    return employees.users;
                }).catch(function (errors) {
                    _this.handleError(errors);
                }),
                pastEmployees: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/institutions/employees?type=past', 'GET').then(function (employees) {
                    return employees.users;
                }).catch(function (errors) {
                    _this.handleError(errors);
                }),
                requests: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/verification_requests', 'GET').then(function (requests) {
                    return requests.verification_requests;
                }).catch(function (errors) {
                    _this.handleError(errors);
                })
            }).then(function (hash) {
                _this.set('user.user', hash.user);
                return hash;
            });
        },


        actions: {
            showAddUserModal: function showAddUserModal() {
                this.set('controller.isAddUserModalShown', true);
            },
            showCreateAccountModal: function showCreateAccountModal(user) {
                this.set('controller.createAccountModalUser', user);
                this.set('controller.isCreateAccountModalShown', true);
            },
            afterSearch: function afterSearch() {
                this.transitionTo('search');
            },
            refresh: function refresh() {
                this.refresh();
            },
            redirectToProfile: function redirectToProfile() {
                this.transitionTo('user.edit');
            }
        }
    });
});