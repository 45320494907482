define('resource-verified-ember/components/user-image-uploader', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['avatar'],
    cloudinarySignatureEndpoint: _emberGetConfig.default.apiFullPath + '/uploads/sign',
    image: null,
    imageSource: Ember.computed('image', function () {
      if (this.get('image').indexOf('http') >= 0) {
        return this.get('image');
      } else {
        return 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + this.get('image');
      }
    }),

    actions: {
      launchUploader: function launchUploader() {
        this.$().parent().find('input[type="file"]').trigger('click');
      },
      removeImage: function removeImage() {
        this.set('image', null);
      },
      userImageUploadComplete: function userImageUploadComplete(e, data) {
        this.set('image', data.result.public_id);
      },
      userImageUploadFailure: function userImageUploadFailure(e) {
        console.log(e);
        this.get('flashMessages').danger('Image upload failure. Please try again.');
      }
    }
  });
});