define('resource-verified-ember/adapters/achievement', ['exports', 'active-model-adapter', 'ember-simple-auth/mixins/data-adapter-mixin', 'ember-get-config'], function (exports, _activeModelAdapter, _dataAdapterMixin, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.default.extend(_dataAdapterMixin.default, {
    host: _emberGetConfig.default.apiHost,
    namespace: _emberGetConfig.default.apiNamespace,
    authorizer: 'authorizer:application',

    urlForQueryRecord: function urlForQueryRecord(query) {
      var url = _emberGetConfig.default.apiFullPath + '/users/' + query.userID + '/achievements/' + query.itemID;
      delete query.userID;
      delete query.itemID;
      return url;
    }
  });
});