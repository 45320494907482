define('resource-verified-ember/instance-initializers/cloudinary-config', ['exports', 'ember-cli-cloudinary/instance-initializers/cloudinary-config'], function (exports, _cloudinaryConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cloudinaryConfig.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _cloudinaryConfig.initialize;
    }
  });
});