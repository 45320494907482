define('resource-verified-ember/services/search', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    results: null,

    clearCachedResults: function clearCachedResults() {
      this.set('results', null);
    },
    search: function search(searchString, transition, searchByID) {
      var _this = this;

      this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/search?' + searchString, 'GET').then(function (results) {
        if (searchByID) {
          localStorage.setItem('criminalCheck', results.users[0].id);
          window.location.href = '/users/' + results.users[0].id;
        } else {
          _this.set('results', results);
        }
      }).catch(function (errors) {
        if (errors.errors && errors.errors.code === 'forbidden') {
          _this.get('flashMessages').danger('Unauthorized. Please contact sales@resourceverified.com for more information.');
        } else {
          _this.handleError(errors);
        }
        _this.set('results', null);
      });
    }
  });
});