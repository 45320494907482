define('resource-verified-ember/controllers/applicants', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      updateDate: function updateDate(field, value) {
        // FORMAT: 2018-02-01 00:00:00
        var formatted = (0, _moment.default)(value).format("YYYY-MM-DD hh:mm:ss");
        this.set(field, formatted);
      },
      updateLocation: function updateLocation(location) {
        this.set('location_id', location.id);
        this.set('selectedLocation', location);
      }
    }
  });
});