define('resource-verified-ember/components/cloudinary-image', ['exports', 'ember-cli-cloudinary/components/cloudinary-image'], function (exports, _cloudinaryImage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _cloudinaryImage.default;
    }
  });
});