define('resource-verified-ember/mixins/verification-item', ['exports'], function (exports) {
                          'use strict';

                          Object.defineProperty(exports, "__esModule", {
                                                    value: true
                          });
                          exports.default = Ember.Mixin.create({
                                                    //using currentAttendance for both employment and education
                                                    currentAttendance: Ember.computed('item.end_at', function () {
                                                                              if (!this.get('item.end_at')) return true;
                                                    }),
                                                    notCurrentAttendance: Ember.computed.not('currentAttendance'),
                                                    isEmployment: Ember.computed('item.constructor.modelName', function () {
                                                                              if (this.get('item.constructor.modelName') === 'work-experience' || this.get('item.company_name')) return true;
                                                    }),
                                                    isEducation: Ember.computed('item.constructor.modelName', function () {
                                                                              if (this.get('item.constructor.modelName') === 'education' || this.get('item.school_name')) return true;
                                                    })
                          });
});