define('resource-verified-ember/services/verifier-response', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    requestID: null,
    action: null
  });
});