define('resource-verified-ember/routes/applicants', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_errorHandler.default, {
        ajax: Ember.inject.service(),
        session: Ember.inject.service(),
        user: Ember.inject.service(),
        verifierResponse: Ember.inject.service(),

        beforeModel: function beforeModel() {
            if (this.get('session.data.authenticated.role') !== 'verifier') {
                this.transitionTo('/');
            }

            if (this.get('session.isAuthenticated') && !this.get('user.user')) {
                location.reload();
            }
        },


        queryParams: {
            job_id: { refreshModel: false },
            job_role: { refreshModel: false },
            from_date: { refreshModel: false },
            to_date: { refreshModel: false },
            location_id: { refreshModel: false }
        },

        queryString: function queryString(params) {
            Object.keys(params).forEach(function (key) {
                return !params[key] ? delete params[key] : '';
            });
            return Object.keys(params).map(function (key) {
                return key + '=' + params[key];
            }).join('&');
        },

        model: function model(params) {
            var _this = this;

            var queryString = this.queryString(params);

            return Ember.RSVP.hash({
                institution: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/verifier/institutions', 'GET').then(function (institutions) {
                    return institutions.institution;
                }).catch(function (errors) {
                    _this.handleError(errors);
                }),
                applicant_request: this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/applicant_requests?' + queryString, 'GET').then(function (applicant_requests) {
                    return applicant_requests.applicant_requests.map(function (applicant) {
                        var applicantImage = applicant.user.image;
                        if (applicantImage && applicantImage.indexOf('http') === -1) applicant.user.image = 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + applicantImage;
                        return applicant;
                    });
                }).catch(function (errors) {
                    _this.handleError(errors);
                })
            });
        },


        actions: {
            openBackgroundCheckModal: function openBackgroundCheckModal(applicantId) {
                this.set('controller.applicantId', applicantId);
                this.set('controller.openBackgroundCheckModal', true);
            },
            clearQueries: function clearQueries() {
                this.set('controller.job_id', '');
                this.set('controller.job_role', '');
                this.set('controller.from_date', '');
                this.set('controller.to_date', '');
                this.set('controller.location_id', '');
                this.set('controller.selectedLocation', '');

                this.send('commitQuery');
            },
            commitQuery: function commitQuery() {
                this.refresh();
            }
        }

    });
});