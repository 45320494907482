define('resource-verified-ember/routes/entry/oauth', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _emberGetConfig, _errorHandler, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, _unauthenticatedRouteMixin.default, {
    session: Ember.inject.service(),

    queryParams: {
      token: {},
      client: {},
      uid: {},
      role: {},
      expiry: {},
      provider: {}
    },

    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (transition.queryParams.token && transition.queryParams.client && transition.queryParams.provider && transition.queryParams.uid && transition.queryParams.role && transition.queryParams.expiry) {
        this.get('session').authenticate('authenticator:oauth', transition.queryParams).then(function () {
          _this.transitionTo('/');
        }).catch(function (err) {
          console.log(err);
        });
      }
    }
  });
});