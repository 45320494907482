define('resource-verified-ember/components/user/status-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userService: Ember.inject.service('user'),

    classNames: ['rv-details'],
    classNameBindings: ['status'],

    status: Ember.computed('user.verified', function () {
      if (this.get('user.disabled')) return 'inactive';
      if (this.get('user.verified')) return 'verified';
      return 'active';
    }),

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('user')) {
        this.set('user', this.get('userService.user'));
      }
    },


    actions: {
      disableAccount: function disableAccount() {
        if (confirm('Are you sure you want to disable ' + this.get('user.name') + '\'s account?')) {
          this.sendAction('disableAction', this.get('user'));
        }
      }
    }
  });
});