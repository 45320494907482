define('resource-verified-ember/components/user/verifiable-item', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/verification-item'], function (exports, _emberGetConfig, _errorHandler, _verificationItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _verificationItem.default, {
    ajax: Ember.inject.service(),
    preloader: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service('user'),

    classNames: ['editable-profile-section'],
    classNameBindings: ['saved', 'item.isNew'],
    saved: Ember.computed.not('item.isNew'),
    areActionIconsShown: Ember.computed('item.isNew', 'item.verified', function () {
      return this.get('item.isNew') || this.get('item.verified');
    }),
    placeholderText: Ember.computed('isEmployment', 'isEducation', function () {
      if (this.get('isEmployment')) return 'Company Name';
      return 'School Name';
    }),
    returnedInstitutions: [],
    paymentOptions: null,

    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('item.descriptions.length') && this.get('item')) {
        if (this.get('isEmployment')) {
          this.get('store').createRecord('description', {
            workExperience: this.get('item')
          });
        }
        if (this.get('isEducation')) {
          this.get('store').createRecord('description', {
            education: this.get('item')
          });
        }
      }

      if (!this.get('item.achievements.length') && this.get('item')) {
        if (this.get('isEmployment')) {
          this.get('store').createRecord('achievement', {
            workExperience: this.get('item')
          });
        }
        if (this.get('isEducation')) {
          this.get('store').createRecord('achievement', {
            education: this.get('item')
          });
        }
      }
    },


    printDescriptionsClass: Ember.computed('item.descriptions', function () {
      if (this.get('item.descriptions.firstObject.isNew')) return ' no-print';
    }),

    printAchievementsClass: Ember.computed('item.achievements', function () {
      if (this.get('item.achievements.firstObject.isNew')) {
        return ' no-print';
      }
    }),

    afterSave: function afterSave() {
      var _this = this;

      this.get('store').queryRecord('user', {}).then(function (updatedUser) {
        _this.set('userService.user', updatedUser);
        _this.set('isVerificationPaymentShown', false);
        _this.set('paymentOptions.stripeToken', null);
        _this.set('paymentOptions.billingSelection', null);
        _this.set('paymentOptions.couponCode', null);
      });
    },
    hasSelectedLocation: function hasSelectedLocation() {
      var location = this.get('item.locationObj');

      return location !== null && location !== undefined && !Ember.isNone(location) && !Ember.isEmpty(location['city']) && !Ember.isEmpty(location['state']) && !Ember.isEmpty(location['country']);
    },


    actions: {
      removeItem: function removeItem() {
        if (this.get('isEmployment')) {
          this.get('user.workExperiences').removeObject(this.get('item'));
        }
        if (this.get('isEducation')) {
          this.get('user.education').removeObject(this.get('item'));
        }
      },
      searchInstitutions: function searchInstitutions(term) {
        var _this2 = this;

        term = term.trim();
        var url = _emberGetConfig.default.apiFullPath + '/autocomplete?type=company&q=' + term;
        if (this.get('isEducation')) url = _emberGetConfig.default.apiFullPath + '/autocomplete?type=university&q=' + term;

        return this.get('ajax').request(url, 'GET').then(function (result) {
          if (!result.institutions.length) _this2.set('showCreate', true);
          _this2.set('returnedInstitutions', result.institutions);
          return _this2.get('returnedInstitutions');
        }).catch(function (errors) {
          _this2.handleError(errors);
        });
      },
      setInstitution: function setInstitution(i) {
        this.set('selectedInstitution', i);
        this.set('item.institution_id', i.id);
        if (this.get('isEmployment')) {
          this.set('item.company_name', i.name);
        } else {
          this.set('item.school_name', i.name);
        }
      },
      showAddInstitution: function showAddInstitution(term) {
        term = term.trim().toLowerCase();
        var institutions = this.get('returnedInstitutions').mapBy('name');
        institutions = institutions.map(function (e) {
          return e.trim().toLowerCase();
        });
        var existingOption = false;
        institutions.forEach(function (e) {
          if (e.indexOf(term) != -1) {
            existingOption = true;
          }
        });
        return !existingOption;
      },
      addInstitution: function addInstitution(name) {
        this.set('selectedInstitution', { name: name });
        if (this.get('isEmployment')) {
          this.set('item.company_name', name);
        } else {
          this.set('item.school_name', name);
        }
      },
      setStartAt: function setStartAt(date) {
        this.set('item.start_at', date);
      },
      setEndAt: function setEndAt(date) {
        this.set('item.end_at', date);
      },
      addDescription: function addDescription() {
        if (this.get('isEmployment')) {
          this.get('store').createRecord('description', {
            workExperience: this.get('item')
          });
        }
        if (this.get('isEducation')) {
          this.get('store').createRecord('description', {
            education: this.get('item')
          });
        }
      },
      removeDescription: function removeDescription(d) {
        this.get('item.descriptions').removeObject(d);
      },
      addAchievement: function addAchievement() {
        if (this.get('isEmployment')) {
          this.get('store').createRecord('achievement', {
            workExperience: this.get('item')
          });
        }
        if (this.get('isEducation')) {
          this.get('store').createRecord('achievement', {
            education: this.get('item')
          });
        }
      },
      removeAchievement: function removeAchievement(a) {
        this.get('item.achievements').removeObject(a);
      },
      setLocation: function setLocation(arrAddress) {
        var _this3 = this;

        var itemLocality = '',
            itemState = '',
            itemCountry = '';
        ;
        this.set('item.locationObj', {});

        // iterate through address_component array
        $.each(arrAddress.address_components, function (i, address_component) {
          if (address_component.types[0] == 'locality') {
            itemLocality = address_component.long_name;
            _this3.set('item.locationObj.city', itemLocality);
          }
          if (address_component.types[0] == 'administrative_area_level_1') {
            itemState = address_component.long_name;
            _this3.set('item.locationObj.state', itemState);
          }
          if (address_component.types[0] == 'country') {
            itemCountry = address_component.long_name;
            _this3.set('item.locationObj.country', itemCountry);
          }
        });
      },
      print: function print() {
        window.print();
      },
      shareLink: function shareLink() {
        var _this4 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/share_tokens', 'POST').then(function (token) {
          _this4.set('shareToken', token.share_token.token);
        }).catch(function (errors) {
          _this4.handleError(errors);
        });

        this.set('showShareLink', true);
      },
      toggleVerifierDisplay: function toggleVerifierDisplay() {
        this.set('isVerifierDisplayShown', !this.get('isVerifierDisplayShown'));
      },
      toggleAchievementVerifierDisplay: function toggleAchievementVerifierDisplay(achievement) {
        achievement.set('isVerifierDisplayShown', !achievement.get('isVerifierDisplayShown'));
      },


      // check if user needs to be billed before saving
      confirmVerification: function confirmVerification() {
        var achievement = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

        if (achievement) this.set('payForAchievement', achievement);

        if (!this.get('userService.userPurchasedFullAccess') && (!this.get('paymentOptions.stripeToken') || !this.get('paymentOptions.billingSelection'))) {
          this.set('isVerificationPaymentShown', true);
        } else {
          if (achievement) {
            this.set('payForAchievement', achievement);
            this.send('saveAchievement', achievement);
          } else if (this.get('isEmployment')) this.send('saveWorkExperience');else if (this.get('isEducation')) this.send('saveEducation');
        }
      },
      submitPayment: function submitPayment(stripeToken, billingSelection, couponCode) {
        var payOpts = {
          stripeToken: stripeToken,
          billingSelection: billingSelection,
          couponCode: couponCode
        };
        this.set('paymentOptions', payOpts);

        if (this.get('payForAchievement')) {
          this.send('saveAchievement', this.get('payForAchievement'));
        } else if (this.get('isEmployment')) {
          this.send('saveWorkExperience');
        } else if (this.get('isEducation')) {
          this.send('saveEducation');
        }
      },
      saveWorkExperience: function saveWorkExperience() {
        var _this5 = this;

        this.set('preloader.saving', true);
        this.set('saveItemDisabled', true);
        // if an end date was entered then the current box checked, null out the date
        if (this.get('currentAttendance')) this.set('item.end_at', null);

        var workExpObj = {
          company_name: this.get('item.company_name'),
          institution_id: this.get('item.institution_id'),
          title: this.get('item.title'),
          location: { country: this.get('item.locationObj.country'), state: this.get('item.locationObj.state'), city: this.get('item.locationObj.city') },
          start_at: this.get('item.start_at'),
          end_at: this.get('item.end_at'),
          descriptions: [],
          achievements: [],
          paymentOptions: this.get('paymentOptions')
        };

        if (!this.hasSelectedLocation()) {
          Ember.get(this, 'flashMessages').danger('Please select a location from the dropdown menu');
          this.set('preloader.saving', false);
          this.set('saveItemDisabled', false);
          return false;
        }

        if (!workExpObj.company_name || !workExpObj.title || Ember.isEmpty(this.get('item.locationObj')) || !workExpObj.start_at) {
          Ember.get(this, 'flashMessages').danger('Please submit all required information');
          this.set('preloader.saving', false);
          this.set('saveItemDisabled', false);
          return false;
        }

        this.get('item.descriptions').forEach(function (d) {
          workExpObj.descriptions.pushObject({
            description: d.get('description')
          });
        });

        this.get('item.achievements').forEach(function (a) {
          workExpObj.achievements.pushObject({
            achievement: a.get('achievement')
          });
        });

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/work_experiences', 'POST', { work_experience: workExpObj }).then(function (resp) {
          _this5.get('user.workExperiences').reload().then(function () {
            // hack https://github.com/emberjs/data/issues/4972#issuecomment-312301105
            // should be able to remove this one https://github.com/emberjs/ember.js/pull/15604 makes it to a release
            _this5.get('store')._removeFromIdMap(_this5.get('item')._internalModel);
            _this5.get('item').unloadRecord();
          });

          var msgPrefix = '';

          if (!Ember.isEmpty(_this5.get('paymentOptions.couponCode'))) {
            msgPrefix = 'Code Accepted. ';
          }

          if (resp.work_experience.verification_status == 'info_required') {
            Ember.get(_this5, 'flashMessages').danger(msgPrefix + 'This Work Experience will require more information, please see below');
          } else {
            Ember.get(_this5, 'flashMessages').success(msgPrefix + 'Work Experience successfully saved for verification');
          }

          _this5.afterSave();
        }).catch(function (errors) {
          _this5.set('saveItemDisabled', false);
          _this5.handleError(errors);
          // let message = errors.responseJSON.errors.message;
          // Ember.get(this, 'flashMessages').danger(message);
        }).finally(function () {
          return _this5.set('preloader.saving', false);
        });
      },
      saveEducation: function saveEducation() {
        var _this6 = this;

        this.set('preloader.saving', true);
        this.set('saveItemDisabled', true);
        // if an end date was entered then the current box checked, null out the date
        if (this.get('currentAttendance')) this.set('item.end_at', null);

        var educationObj = {
          school_name: this.get('item.school_name'),
          institution_id: this.get('item.institution_id'),
          degree: this.get('item.degree'),
          start_at: this.get('item.start_at'),
          end_at: this.get('item.end_at'),
          descriptions: [],
          achievements: [],
          paymentOptions: this.get('paymentOptions')
        };

        if (!educationObj.school_name || !educationObj.degree || !educationObj.start_at) {
          Ember.get(this, 'flashMessages').danger('Please submit all required information');
          this.set('preloader.saving', false);
          this.set('saveItemDisabled', false);
          return false;
        }

        this.get('item.descriptions').forEach(function (d) {
          educationObj.descriptions.pushObject({
            description: d.get('description')
          });
        });

        this.get('item.achievements').forEach(function (a) {
          educationObj.achievements.pushObject({
            achievement: a.get('achievement')
          });
        });

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/education', 'POST', { education: educationObj }).then(function (resp) {
          _this6.get('user.education').reload().then(function () {
            // hack https://github.com/emberjs/data/issues/4972#issuecomment-312301105
            // should be able to remove this one https://github.com/emberjs/ember.js/pull/15604 makes it to a release
            _this6.get('store')._removeFromIdMap(_this6.get('item')._internalModel);
            _this6.get('item').unloadRecord();
          });

          var msgPrefix = '';

          if (!Ember.isEmpty(_this6.get('paymentOptions.couponCode'))) {
            msgPrefix = 'Code Accepted. ';
          }

          if (resp.education.verification_status == 'info_required') {
            Ember.get(_this6, 'flashMessages').danger(msgPrefix + 'This Education will require more information, please see below');
          } else {
            Ember.get(_this6, 'flashMessages').success(msgPrefix + 'Education successfully saved for verification');
          }

          _this6.afterSave();
        }).catch(function (errors) {
          _this6.set('preloader.saving', false);
          _this6.set('saveItemDisabled', false);
          _this6.handleError(errors);
        }).finally(function () {
          return _this6.set('preloader.saving', false);
        });
      },
      saveDescription: function saveDescription(description) {
        var _this7 = this;

        this.set('preloader.saving', true);
        if (!description.get('description')) {
          Ember.get(this, 'flashMessages').danger('Please add a description');
          this.set('preloader.saving', false);
          return false;
        }

        var type = 'work_experiences';
        if (this.get('isEducation')) type = 'education';

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/' + type + '/' + this.get('item.id') + '/descriptions', 'POST', { description: { description: description.get('description') } }).then(function () {
          description.unloadRecord();

          if (_this7.get('isEmployment')) _this7.get('user.workExperiences').reload();
          if (_this7.get('isEducation')) _this7.get('user.education').reload();

          Ember.get(_this7, 'flashMessages').success('Description added');
        }).catch(function (errors) {
          _this7.handleError(errors);
        }).finally(function () {
          return _this7.set('preloader.saving', false);
        });
      },
      saveAchievement: function saveAchievement(achievement) {
        var _this8 = this;

        this.set('preloader.saving', true);
        if (!achievement.get('achievement')) {
          Ember.get(this, 'flashMessages').danger('Please add achievement text');
          this.set('preloader.saving', false);
          return false;
        }

        var type = 'work_experiences';
        if (this.get('isEducation')) type = 'education';

        var data = {
          achievement: {
            achievement: achievement.get('achievement'),
            paymentOptions: this.get('paymentOptions')
          }
        };

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + this.get('user.id') + '/' + type + '/' + this.get('item.id') + '/achievements', 'POST', data).then(function (resp) {
          achievement.unloadRecord();

          if (_this8.get('isEmployment')) _this8.get('user.workExperiences').reload();
          if (_this8.get('isEducation')) _this8.get('user.education').reload();

          var msgPrefix = '';

          if (!Ember.isEmpty(_this8.get('paymentOptions.couponCode'))) {
            msgPrefix = 'Code Accepted. ';
          }

          if (resp.achievement.verification_status == 'info_required') {
            Ember.get(_this8, 'flashMessages').danger(msgPrefix + 'This Achievement will require more information, please see below');
          } else {
            Ember.get(_this8, 'flashMessages').success(msgPrefix + 'Achievement successfully saved for verification');
          }

          _this8.afterSave();
        }).catch(function (errors) {
          _this8.handleError(errors);
        }).finally(function () {
          return _this8.set('preloader.saving', false);
        });
      }
    }
  });
});