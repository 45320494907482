define('resource-verified-ember/components/admin/add-coupon-modal', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/modal'], function (exports, _emberGetConfig, _errorHandler, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_errorHandler.default, _modal.default, {
    classNames: ['add-coupon-modal'],

    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    action: 'POST',
    urlID: '',

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('coupon')) {
        this.set('couponCode', this.get('coupon.code'));
        this.set('discountType', this.get('coupon.discount_type'));
        this.set('discountAmount', this.get('coupon.discount_amount'));
        this.set('expiresAt', this.get('coupon.expires_at'));
        this.set('appliesTo', this.get('coupon.applies_to'));
        this.set('maxUses', this.get('coupon.max_uses'));
        this.set('maxUsers', this.get('coupon.max_users'));
        this.set('stripeCode', this.get('coupon.stripe_code'));
        this.set('action', 'PUT');
        this.set('urlID', '/' + this.get('coupon.id'));
      }
    },


    discountTypeSymbol: Ember.computed('discountType', function () {
      switch (this.get('discountType')) {
        case 'percent':
          return '(%)';
          break;
        case 'fixed':
          return '($)';
          break;
        default:
          return '';
          break;
      }
    }),

    maintenanceFeeSelected: Ember.computed('appliesTo', function () {
      return this.get('appliesTo') && this.get('appliesTo').indexOf('MaintenanceFee') >= 0;
    }),

    billables: Ember.A(['WorkExperience', 'Education', 'Achievement', 'BasicBackgroundCheck', 'ExecutiveBackgroundCheck', 'MaintenanceFee']),

    close: function close() {
      this._super.apply(this, arguments);
      this.set('coupon', null);
    },


    actions: {
      updateAppliesTo: function updateAppliesTo(newBillables) {
        this.set('appliesTo', newBillables);
      },
      setExpiresAt: function setExpiresAt(expiration) {
        this.set('expiresAt', expiration);
      },
      saveCoupon: function saveCoupon() {
        var _this = this;

        var couponObj = {
          code: this.get('couponCode'),
          discount_type: this.get('discountType'),
          discount_amount: this.get('discountAmount'),
          applies_to: this.get('appliesTo'),
          expires_at: this.get('expiresAt'),
          max_uses: this.get('maxUses'),
          max_users: this.get('maxUsers'),
          stripe_code: this.get('stripeCode')
        };

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/coupons' + this.get('urlID'), this.get('action'), { coupon: couponObj }).then(function (coupon) {
          _this.sendAction('reload');
          _this.get('flashMessages').success('Coupon saved');
          _this.close();
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      }
    }
  });
});