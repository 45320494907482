define('resource-verified-ember/controllers/company', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    cantSearch: Ember.computed('model.user.role', 'model.user.premium', function () {
      if (this.get('model.user.role') === 'verifier' && !this.get('model.user.premium')) {
        return true;
      }

      return false;
    }),

    currentEmployeesLabel: Ember.computed(function () {
      if (this.get('model.company.institution_type') == 'company') {
        return 'Verified - Current Employees';
      }
      return 'Verified - Current Students';
    }),

    pastEmployeesLabel: Ember.computed(function () {
      if (this.get('model.company.institution_type') == 'company') {
        return 'Verified - Past Employees';
      }
      return 'Verified - Alumni';
    })
  });
});