define('resource-verified-ember/components/user/verifier-display', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/user', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/verification-item'], function (exports, _emberGetConfig, _user, _errorHandler, _verificationItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_user.default, _errorHandler.default, _verificationItem.default, {
    classNames: ['verifier-display'],
    classNameBindings: ['show'],

    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),

    linkID: Ember.computed('item.verification_request.id', 'item.verifier.id', function () {
      if (this.get('item.verifier') && this.get('item.verifier.profile_created')) {
        return this.get('item.verifier.id');
      } else {
        return null;
      }
    }),

    link: Ember.computed('linkID', function () {
      if (!Ember.isEmpty(this.get('linkID'))) {
        return '/users/' + this.get('linkID');
      }
    }),

    tooltip: Ember.computed('linkID', 'item.verifier.id', function () {
      if (!this.get('linkID') || !this.get('item.verifier.profile_created')) {
        return 'This user does not have a profile';
      }
    }),

    image: Ember.computed('item.verification_request.image', 'item.verifier.image', function () {
      if (this.get('item.verifier')) {
        return this.imageSource(this.get('item.verifier'));
      } else {
        return '/images/default-avatar.jpg';
      }
    }),

    displayReset: Ember.computed('session.isAuthenticated', 'session.data.authenticated.role', 'item.verification_status', function () {
      return this.get('session.isAuthenticated') && this.get('session.data.authenticated.role') == 'admin' && this.get('item.verification_status') == 'pending';
    }),

    actions: {
      reset: function reset() {
        var _this = this;

        var verifiable = this.get('item');
        if (this.get('session.isAuthenticated') && this.get('session.data.authenticated.role') == 'admin') {
          if (confirm('Are you sure you want to reset this verification?') && verifiable.get('verification_status') == 'pending') {
            var type = "";
            if (this.get('isEmployment')) {
              type = 'WorkExperience';
            } else if (this.get('isEducation')) {
              type = 'Education';
            } else {
              type = 'Achievement';
            }

            this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/verifications/' + verifiable.get('id') + '?type=' + type, 'DELETE').then(function (resp) {
              verifiable.set('verification_status', 'info_required');
              verifiable.set('verifier', null);
              verifiable.set('verification_request', null);
              Ember.get(_this, 'flashMessages').success('Verification was reset successfully.');
            }).catch(function (errors) {
              console.log(errors);
              _this.handleError(errors);
            });
          }
        }
      }
    }
  });
});