define('resource-verified-ember/components/user/share-link', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['share-link'],

    close: function close() {
      Ember.$(document).off('click.hideShareLink');
      this.set('show', false);
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.later(function () {
        Ember.$(document).on('click.hideShareLink', function (e) {
          if (!$(e.target).hasClass('share-link')) {
            _this.close();
          }
        });
      });
    },


    link: Ember.computed('token', function () {
      return window.location.host + '/users/' + this.get('userID') + '?link=' + this.get('token');
    })
  });
});