define('resource-verified-ember/models/education', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    school_name: _emberData.default.attr('string'),
    degree: _emberData.default.attr('string'),
    start_at: _emberData.default.attr('string'),
    end_at: _emberData.default.attr('string'),
    verification_status: _emberData.default.attr('string'),
    verified: _emberData.default.attr('boolean'),
    verifier: _emberData.default.attr({}),
    verification_request: _emberData.default.attr({}),
    document_count: _emberData.default.attr('number'),
    descriptions: _emberData.default.hasMany('description'),
    achievements: _emberData.default.hasMany('achievement'),
    user: _emberData.default.belongsTo('user')
  });
});