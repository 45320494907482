define('resource-verified-ember/routes/user/public', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'resource-verified-ember/mixins/user'], function (exports, _emberGetConfig, _errorHandler, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, _user.default, {
    ajax: Ember.inject.service(),
    // user: Ember.inject.service(),

    share: '',

    model: function model(params, transition) {
      var _this = this;

      var url = _emberGetConfig.default.apiFullPath + '/users/' + params.id,
          share = '';

      if (transition.queryParams.link) {
        share = '?share_token=' + transition.queryParams.link;
        this.set('share', share);
      }

      if (localStorage.getItem('criminalCheck') === params.id) {
        this.set('showCriminalCheck', true);
      }

      this.get('ajax').request('' + url + share, 'GET').then(function (user) {
        var newUser = _this.store.peekRecord('user', user.user.id);

        if (newUser) {
          // this.store.unloadRecord(newUser);
          _this.store.unloadAll('user');
          _this.store.unloadAll('workExperience');
          _this.store.unloadAll('education');
          _this.store.unloadAll('achievement');
          newUser = null;
        }

        if (!newUser) {
          newUser = _this.store.createRecord('user', user.user);
          _this.set('controller.imageSource', _this.imageSource(newUser));

          _this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + params.id + '/work_experiences' + share, 'GET').then(function (workExperiences) {
            workExperiences.work_experiences.forEach(function (we) {
              var newAchievements = [];
              we.achievements.forEach(function (a) {
                newAchievements.pushObject(_this.get('store').createRecord('achievement', a));
              });

              var newDescriptions = [];
              we.descriptions.forEach(function (d) {
                newDescriptions.pushObject(_this.get('store').createRecord('description', d));
              });

              we.achievements = newAchievements;
              we.descriptions = newDescriptions;

              we.public = true;
              var newWe = _this.store.createRecord('workExperience', we);
              newUser.get('workExperiences').pushObject(newWe);
            });

            newUser.set('sortedWorkExperiences', _this.get('sortedWorkExperiences'));
          }).catch(function (errors) {
            _this.handleError(errors);
          });

          _this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/' + params.id + '/education' + share, 'GET').then(function (ed) {
            ed.educations.forEach(function (ed) {
              var newAchievements = [];
              ed.achievements.forEach(function (a) {
                newAchievements.pushObject(_this.get('store').createRecord('achievement', a));
              });

              var newDescriptions = [];
              ed.descriptions.forEach(function (d) {
                newDescriptions.pushObject(_this.get('store').createRecord('description', d));
              });

              ed.achievements = newAchievements;
              ed.descriptions = newDescriptions;

              ed.public = true;
              var newEd = _this.store.createRecord('education', ed);
              newUser.get('education').pushObject(newEd);
            });

            newUser.set('sortedEducation', _this.get('sortedEducation'));
          }).catch(function (errors) {
            _this.handleError(errors);
          });
        }

        _this.set('controller.user', newUser);
      }).catch(function (errors) {
        _this.handleError(errors);
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('share', this.get('share'));

      controller.set('showCriminalCheck', this.get('showCriminalCheck'));
      localStorage.removeItem('criminalCheck');
    },


    actions: {
      showNewMessage: function showNewMessage() {
        this.set('controller.isNewMessageShown', true);
      },
      disableAccount: function disableAccount(userToDisable) {
        var _this2 = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/admin/users/' + userToDisable.get('id'), 'DELETE').then(function (user) {
          _this2.get('flashMessages').success('Account disabled.');
          location.reload();
        }).catch(function (err) {
          _this2.get('flashMessages').danger(err);
        });
      }
    }
  });
});