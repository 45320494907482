define('resource-verified-ember/mixins/user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Mixin.create({
    user: null,

    getUserRoute: function getUserRoute() {
      if (this.get('user')) {
        switch (this.get('user.role')) {
          case 'admin':
            return '/admin';
            break;
          case 'verifier':
            return '/company';
            break;
          default:
            if (this.get('user.justConfirmed')) {
              return 'user.billing';
            } else {
              return 'user.edit';
            }
            break;
        }
      }
    },
    sortVerificationItems: function sortVerificationItems(a, b) {
      // new items(being created) on top
      // if( b.get('isNew') ) { return 1; }

      // present
      if (!a.get('end_at') && !b.get('end_at')) {
        // both are present positions, sort by start date
        if (new Date(a.get('start_at')).getTime() > new Date(b.get('start_at')).getTime()) {
          return -1;
        }
        if (new Date(a.get('start_at')).getTime() < new Date(b.get('start_at')).getTime()) {
          return 1;
        }
      }
      if (!a.get('end_at')) {
        return -1;
      }
      if (!b.get('end_at')) {
        return 1;
      }

      // item has end date
      if (new Date(a.get('end_at')).getTime() > new Date(b.get('end_at')).getTime()) {
        return -1;
      }
      if (new Date(a.get('end_at')).getTime() < new Date(b.get('end_at')).getTime()) {
        return 1;
      }

      return 0;
    },


    sortedWorkExperiences: Ember.computed.sort('controller.user.workExperiences', function (a, b) {
      return this.sortVerificationItems(a, b);
    }),

    sortedEducation: Ember.computed.sort('controller.user.education', function (a, b) {
      return this.sortVerificationItems(a, b);
    }),

    // imageSource           : Ember.computed('controller.user.image', function() {
    //                           console.log( `https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/${this.get('controller.user.image')}` );
    //                           return `https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/${this.get('controller.user.image')}`;
    //                         }),
    imageSource: function imageSource(user) {
      if (user == null) {
        return '/images/default-avatar.jpg';
      }

      var isObject = user.image !== null && _typeof(user.image) === 'object';

      if (isObject && user.get('image')) {
        if (user.get('image').indexOf('http') >= 0) {
          return user.get('image');
        } else {
          return 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + user.get('image');
        }
      } else if (!isObject && user.image) {
        if (user.image.indexOf('http') >= 0) {
          return user.image;
        } else {
          return 'https://res.cloudinary.com/resourceverified/image/upload/w_128,h_142,c_fill/' + user.image;
        }
      } else {
        return '/images/default-avatar.jpg';
      }
    },


    title: Ember.computed('sortedWorkExperiences', 'sortedEducation', function () {
      var savedWorkExperience = this.get('sortedWorkExperiences').rejectBy('isNew'),
          savedEducation = this.get('sortedEducation').rejectBy('isNew');

      if (savedWorkExperience.get('length')) {
        return savedWorkExperience.get('firstObject.title');
      } else if (savedEducation.get('length')) {
        return savedEducation.get('firstObject.title');
      }
    }),

    location: Ember.computed('sortedWorkExperiences', 'sortedEducation', function () {
      var savedWorkExperience = this.get('sortedWorkExperiences').rejectBy('isNew'),
          savedEducation = this.get('sortedEducation').rejectBy('isNew');

      if (savedWorkExperience.get('length')) {
        return savedWorkExperience.get('firstObject.location');
      } else if (savedEducation.get('length')) {
        return savedEducation.get('firstObject.location');
      }
    })
  });
});