define('resource-verified-ember/routes/entry/signup', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _emberGetConfig, _errorHandler, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, _unauthenticatedRouteMixin.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    actions: {
      signUp: function signUp() {
        var _this = this;

        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/auth', 'POST', {
          name: this.get('controller.fullName'),
          email: this.get('controller.email'),
          password: this.get('controller.password')
        }).then(function () {
          _this.transitionTo('entry/verify');
        }).catch(function (errors) {
          _this.handleError(errors);
        });
      },
      authenticateOauth: function authenticateOauth(provider) {
        window.location.replace(_emberGetConfig.default.apiFullPath + '/auth/' + provider);
      }
    }
  });
});