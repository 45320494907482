define('resource-verified-ember/routes/user/account', ['exports', 'ember-get-config', 'resource-verified-ember/mixins/error-handler'], function (exports, _emberGetConfig, _errorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_errorHandler.default, {
    ajax: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    preloader: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.get('controller').set('passwordOrEmailEditable', this.get('session.data.authenticated.provider') === 'email');
    },


    actions: {
      updatePassword: function updatePassword() {
        var _this = this;

        if (this.get('controller.newPassword') !== this.get('controller.newPasswordConfirmation')) {
          this.get('flashMessages').danger('New password and confirmation must match');
          return false;
        }

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/update_password', 'PUT', {
          user: {
            current_password: this.get('controller.oldPassword'),
            password: this.get('controller.newPassword')
          }
        }).then(function () {
          _this.get('flashMessages').success('Password updated');
          _this.set('controller.oldPassword', null);
          _this.set('controller.newPassword', null);
          _this.set('controller.newPasswordConfirmation', null);
        }).catch(function (errors) {
          _this.handleError(errors);
        }).finally(function () {
          return _this.set('preloader.saving', false);
        });
      },
      updateEmail: function updateEmail() {
        var _this2 = this;

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/users/update_email', 'PUT', {
          user: {
            email: this.get('controller.newEmail')
          }
        }).then(function () {
          _this2.get('flashMessages').success('An email was sent to ' + _this2.get('controller.newEmail') + '. Please follow the instructions to confirm your new email.');
          _this2.set('controller.newEmail', null);
        }).catch(function (errors) {
          _this2.handleError(errors);
        }).finally(function () {
          return _this2.set('preloader.saving', false);
        });
      },
      updateBillingInformation: function updateBillingInformation(token) {
        var _this3 = this;

        this.set('preloader.saving', true);
        this.get('ajax').request(_emberGetConfig.default.apiFullPath + '/billing', 'PUT', {
          stripe_token: token
        }).then(function () {
          _this3.get('flashMessages').success('Billing information updated');
          location.reload();
        }).catch(function (errors) {
          _this3.handleError(errors);
        }).finally(function () {
          return _this3.set('preloader.saving', false);
        });
      }
    }
  });
});